<script lang="ts">

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import CustomerAccountForm from "./CustomerAccountForm.vue";
    import Loader from "@/components/global/Loader.vue";
    import {ICustomerAccountDTO, ICustomerDTO, IEditCustomerAccountDTO} from "@/types/AutoGenerated/reinforcedTypings";
    import {IUser} from "@/store/user";
    import {Prop} from "vue-property-decorator";

    @Component({
        name: "CustomAccountCard",
        components: {Loader, CustomerAccountForm}
    })

    export default class CustomAccountCard extends Vue {

      @Prop({}) customer : ICustomerDTO
        @Getter userToBook : IUser;
        @Action("putCustomerAccount") putCustomerAccount : (appointment: IEditCustomerAccountDTO)  => Promise<any>;

        public editUser = false;
        public loading = false;

        get customerAccount(){
          return this.$store.getters.loggedInCustomer;
        }
        created() {

        }

        public async editCustomerAccount(customerAccountUpdate: IEditCustomerAccountDTO): Promise<void> {
            this.loading = true;
            customerAccountUpdate.password = this.customerAccount.password;
            if(customerAccountUpdate.phoneNumber == null || customerAccountUpdate.phoneNumber == "") {
                customerAccountUpdate.phoneNumber = this.customerAccount.phoneNumber;
            }

            let response = await this.putCustomerAccount(customerAccountUpdate);
            this.loading = false;
            this.editUser = false;

        }
    }

</script>

<template>
    <div >
        <v-card flat >
            <v-card-title v-if="customer.firstName != null && customer.firstName.length > 0">{{customer.firstName}}</v-card-title>

            <v-card-text  class="hidden-sm-and-down">
                        <v-avatar size="96" class="mr-4 hidden-sm-and-down">
                            <img src="../../../public/img/customer-user-2.svg" alt="Avatar">
                        </v-avatar>
            </v-card-text>

            <v-card-text>
                <span v-if="customer.phoneNumber != null && customer.address.phoneNumber > 0">{{customer.phoneNumber}}</span>
                <br v-if="customer.phoneNumber != null && customer.address.phoneNumber > 0">
                <span v-if="customer.address != null && customer.address.length > 0">{{customer.address}}</span>
                <br  v-if="customer.address != null && customer.address.length > 0">
                <span v-if="customer.postalCode != null && customer.postalCode.length > 0">{{customer.postalCode}}, {{customer.city}}</span>
                <br v-if="customer.postalCode != null && customer.postalCode.length > 0">
                <span>{{customer.emailAddress}}</span>
            </v-card-text>


<!--                <v-card-actions>-->
<!--                    <v-btn dark color="#5C6BC0" v-if="!editUser" @click="editUser = true"> Rediger din informasjon</v-btn>-->
<!--                    <v-btn  v-if="editUser" @click="editUser = false"> Lukk redigering</v-btn>-->
<!--                </v-card-actions>-->

            </v-card>


            <v-expand-transition>
                <v-card
                        v-show="editUser"
                        height="auto"
                        width="auto"
                        class="mx-auto"
                >

                    <v-card-title>
                        <span>Rediger bruker</span>
                    </v-card-title>

                    <div class="pa-5">
                        <loader v-if="loading"></loader>
                        <customer-account-form  v-else :showPassword="false" buttonTet="Lagre" @submit="editCustomerAccount"></customer-account-form>
                    </div>

                </v-card>
            </v-expand-transition>

    </div>
</template>


<style scoped>

</style>
