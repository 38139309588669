<script lang="ts">
"use strict";

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { actionStringAppointments } from "../../store/appointments/appointments";
import { IAppointmentForm } from "@/store/appointments/appointmentTypings";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { IDialog } from "../../store/dialog";
import Verification from "@/views/user/Verification.vue";
import CustomAccountCard from "./CustomerAccountCard.vue";
import { ICustomerAccount } from "../../store/customerAccounts";
import {
  IAppointmentAnonymousPostDTO,
  IPostBookingDTO,
  IUserDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { IAvailableHour } from "@/store/workingHours";
import {
  IAppointmentTypeDTO,
  ICustomerAccountDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { IServiceBookingDetail } from "@/store/appointments/appointmentTypes";
import { formatCurrency } from "@/Utilities/currencyHelper";
@Component({
  name: "booking-form",
  components: { CustomAccountCard, Verification },
})
export default class bookingForm extends Vue {
  @Prop(String) public userId: string | null;
  @Prop({ default: null }) selectedServices: IAppointmentTypeDTO[];
  @Prop({ default: null }) serviceBookingsDetails: IServiceBookingDetail[];

  public hasSelectedNewUser: boolean = false;

  public hasBooked: boolean = false;
  public firstName: string = "";
  public emailAddress: string = "";
  public phoneNumber: string = "";
  public address: string = "";
  public postalCode: string = "";
  public comment: string = "";
  public city: string = "";
  public phoneNumberCountryCode: string = "";
  public newUserPassword: string = "";
  public selected: String | number = this.$t(
    "bookingForm.selectDepartment"
  ).toString();
  public requiresVerification: boolean = false;
  public appointmentToBeVerified = null;

  @Action public bookAppointmentAsCustomer: Function;
  @Action(actionStringAppointments.BOOK_APPOINTMENT_AS_ANONYMOUS)
  bookAppointmentAsAnonymous: (appointment: IPostBookingDTO) => Promise<void>;
  @Action public getAppointmentTypesAsCustomer: Function;

  @Action public getCustomerAccountByPhoneNumber: Function;
  @Action public postSnackbarMessage: Function;
  @Action public addToDialogQueue: Function;
  @Action public sendVerificationCode: Function;
  @Getter public appointmentType: IAppointmentTypeDTO;
  @Getter public selectHour: IAvailableHour;
  @Getter public userToBook: IUserDTO;

  @Mutation setBookingStep;
  public emailReminder: boolean = false;
  public emailReceipt: boolean = false;

  public smsReminder: boolean = false;
  public smsReceipt: boolean = false;
  public signupNewsletter: boolean = false;

  public agreeWithConsent: boolean = true;

  @Getter public customerAccount: ICustomerAccountDTO;

  @Watch("customerAccount")
  onToggleSwitchChanged(val, oldVal: boolean) {
    this.firstName = val.firstName;
    this.emailAddress = val.emailAddress;
    this.phoneNumber = val.phoneNumber;
    this.address = val.address;
    this.city = val.city;
    this.postalCode = val.postalCode;
    this.newUserPassword = val.password;
    this.phoneNumberCountryCode = val.phoneNumberCountryCode;
  }

  public nameRules = [
    (v) => !!v || this.$t("bookingForm.nameRequired").toString(),
  ];
  public addressRules = [
    (v) => !!v || this.$t("bookingForm.addressRequired").toString(),
  ];
  public postalCodeRules = [
    (v) => !!v || this.$t("bookingForm.postalCodeRequired").toString(),
  ];
  public emailRules = [
    (v) => !!v || this.$t("bookingForm.emailRequired").toString(),
    (v) => /.+@.+\..+/.test(v) || this.$t("bookingForm.emailValid").toString(),
  ];
  public phoneRules = [
    (v) => !!v || this.$t("bookingForm.phoneRequired").toString(),
    (v) => /^\d+$/.test(v) || this.$t("bookingForm.phoneValid").toString(),
  ];

  public passwordRules = [
    (v) => !!v || this.$t("bookingForm.passwordRequired").toString(),
    (v) => v.length > 5 || this.$t("bookingForm.passwordLength").toString(),
  ];

  public async bookAppointment(): Promise<void> {
    if (this.hasBooked) {
      return;
    }
    this.hasBooked = true;

    //@ts-ignore
    const currentSelectedDate: Date = new Date(
      //@ts-ignore
      Date.parse(this.selectHour.date)
    );

    let cancelUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      "/kansellere-time";

    // Extract hour and minute from the date
    const selectedHour = currentSelectedDate.getHours();
    const selectedMinute = currentSelectedDate.getMinutes();

    //Data to be posted
    let newAppointment: IPostBookingDTO = {
      year: currentSelectedDate.getFullYear(),
      month: currentSelectedDate.getMonth() + 1,
      day: currentSelectedDate.getDate(),
      hour: selectedHour,
      minute: selectedMinute,
      comment: this.comment,
      serviceBookings: this.selectedServices,
      phoneNumber: this.customerAccount.phoneNumber,
      phoneNumberCountryCode: this.customerAccount.phoneNumberCountryCode,
      emailAddress: this.customerAccount.emailAddress,
      firstName: this.customerAccount.firstName,
      lastName: "", // HARDCODED
      cancelUrl: cancelUrl,
      address: this.customerAccount.address,
      postalCode: this.customerAccount.postalCode,
      password: this.customerAccount.password,
      smsReminder: this.smsReminder,
      smsReceipt: this.smsReceipt,
      signupNewsletter: this.signupNewsletter,
      emailReceipt: this.emailReceipt,
      emailReminder: this.emailReminder,
      userId: this.userId,
      city: this.city,
      customerAccountIdentificationCode: "",
      //@ts-ignore //TODO: Custom fields is probably broken, it doesnt exist on the DTO
      customFields: this.customerAccount.customFields,
    };

    let sendReminder = this.smsReminder || this.emailReminder;
    let sendReceipt = this.smsReceipt || this.emailReceipt;

    let boxInfo = { reminder: sendReminder, receipt: sendReceipt };

    if (this.userToBook.useCustomerAccounts) {
      //TODO: Update for make this functional in the future. RIght now: do it the old way
      if (this.customerAccount.verified) {
        await this.bookAppointmentAsAnonymous(newAppointment);
        this.$emit("finishedBooked", boxInfo);
      } else {
        await this.sendVerificationCode(newAppointment);
        this.requiresVerification = true;
        this.appointmentToBeVerified = newAppointment;
      }
    } else {
      let res: any = await this.bookAppointmentAsAnonymous(newAppointment);
      if (res.data === "Verification required") {
        this.requiresVerification = true;
        this.appointmentToBeVerified = newAppointment;
      } else {
        this.$emit("finishedBooked", boxInfo);
      }
    }
  }

  public created(): void {
    if (this.userToBook.customerReceiptAndReminderMode == "email") {
      this.emailReceipt = false;
      this.emailReminder = false;
    }

    if (this.userToBook.customerReceiptAndReminderMode == "sms") {
      this.smsReceipt = false;
      this.smsReminder = false;
    }
  }

  mounted() {
    this.hasBooked = false;
    this.hasSelectedNewUser = false;
    this.firstName = this.customerAccount.firstName;
    this.emailAddress = this.customerAccount.emailAddress;
    this.phoneNumber = this.customerAccount.phoneNumber;
    this.address = this.customerAccount.address;
    this.postalCode = this.customerAccount.postalCode;
    this.newUserPassword = this.customerAccount.password;

    localStorage.setItem("firstName", this.firstName);
    localStorage.setItem("emailAddress", this.emailAddress);
    localStorage.setItem("phoneNumber", this.phoneNumber);
    localStorage.setItem("address", this.address);
    localStorage.setItem("postalCode", this.postalCode);
    localStorage.setItem("city", this.city);
    localStorage.setItem("phoneNumberCountryCode", this.phoneNumberCountryCode);
  }

  get prettySelectedDate() {
    let date = this.selectHour.date;
    return formatDate(date, FormatSpaceType.DOT);
  }

  async attemptLoginCustomerAccount() {
    let response = await this.getCustomerAccountByPhoneNumber(this.phoneNumber);
    if (response == "New") {
      this.hasSelectedNewUser = true;
    }
  }

  async onVerified() {
    if (this.appointmentToBeVerified != null) {
      let bookResponse = await this.bookAppointmentAsAnonymous(
        this.appointmentToBeVerified
      );
      this.$emit("finishedBooked");
    }
  }

  sum(total, num) {
    return total + num;
  }

  get totalDuration() {
    if (this.selectedServices == null) {
      return 0;
    }
    let total = 0;
    this.serviceBookingsDetails.forEach((details: any) => {
      let dur = details.service.durationInMinutes;
      total += dur;
      let children = details.children;
      children.forEach((child) => {
        total += child.durationInMinutes;
      });
    });
    return total;
  }

  get totalPrice() {
    let total = 0;
    this.serviceBookingsDetails.forEach((details) => {
      let dur = details.service.price;
      if (
        details.service.discountPrice != null &&
        details.service.discountPrice != -1
      ) {
        total += details.service.discountPrice;
      } else {
        total += dur;
      }
      let children = details.children;
      if (children != null) {
        children.forEach((child) => {
          if (child.discountPrice != null && child.discountPrice != -1) {
            total += child.discountPrice;
          } else {
            total += child.price;
          }
        });
      }
    });
    return formatCurrency(total);
  }

  convertMinsToHrsMins(mins) {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    //@ts-ignore
    h = h < 10 ? "" + h : h;
    //@ts-ignore
    m = m < 10 ? "" + m : m;
    if (h == 1 && m == 0) {
      return "60 min";
    }
    if (h == 0) {
      return m + "min";
    }
    return `${h}t ${m}m`;
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return this.formatTime(hour, minute);
  }

  formatTime(hour: string | number, minute: string | number): string {
    let formattedTimeAsString: string = "";
    let localHour: number;
    let localMinute: number;

    //cast to number
    localHour = hour as number;
    localMinute = minute as number;

    //Add zero logic
    let addedZeroToHour = localHour < 10 ? "0" : "";
    let addedZeroToMinute = localMinute < 10 ? "0" : "";

    formattedTimeAsString =
      addedZeroToHour + localHour + ":" + addedZeroToMinute + localMinute;

    return formattedTimeAsString;
  }

  getTitle(service): string {
    let title = service.service.title;
    let index = 0;
    if (service.children && service.children.length > 0) {
      title += "(+ ";
      service.children.forEach((x) => {
        if (index != 0) {
          title += ", ";
        }
        title += x.title;
        index++;
      });
      title += ")";
    }
    return title;
  }

  getPrice(service): string {
    let price = service.service.price;

    if (
      service.service.discountPrice != null &&
      service.service.discountPrice != -1
    ) {
      price = service.service.discountPrice;
    }

    if (service.children) {
      service.children.forEach((x) => {
        let childPrice = x.price;
        if (x.discountPrice != null && x.discountPrice != -1) {
          childPrice = x.discountPrice;
        }
        price += childPrice;
      });
    }

    return formatCurrency(price);
  }
}
</script>

<template>
  <div>
    <v-row justify="center">
      <v-col
        justify="center"
        align-items="center"
        align-content="center"
        style="background-color: #ececf3; padding: 0; padding-top: 0px"
        sm="12"
        md="8"
        lg="6"
      >
        <!--                <div v-if="requiresVerification">-->
        <!--                  <verification @verified="onVerified"></verification>-->
        <!--                </div>-->

        <div v-if="!requiresVerification && selectedServices != null">
          <v-row
            align-self="center"
            justify="center"
            align="center"
            align-content="center"
          >
            <!-- <v-col>
                         <custom-account-card></custom-account-card>
                     </v-col> -->

            <v-col align-self="center" justify="center" align-content="center">
              <v-card flat>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="service in serviceBookingsDetails">
                        <td style="text-align: center">
                          {{ $t("bookingForm.service") }}
                        </td>
                        <td style="text-align: center">
                          {{ getTitle(service) }} ●
                          {{ $t("currency", { amount: getPrice(service) }) }} ●
                          {{ service.service.durationInMinutes }} min
                        </td>
                      </tr>
                      <tr v-for="service in serviceBookingsDetails">
                        <td
                          v-if="
                            service.description != null &&
                            service.description.length > 0
                          "
                          style="text-align: center"
                        >
                          {{ $t("bookingForm.description") }}
                        </td>
                        <td
                          v-if="
                            service.description != null &&
                            service.description.length > 0
                          "
                          style="text-align: center"
                        >
                          {{ service.description }}
                        </td>
                      </tr>

                      <tr>
                        <td style="text-align: center">
                          {{ $t("bookingForm.time") }}
                        </td>
                        <td style="text-align: center">
                          {{ prettySelectedDate }} {{ selectHour.label }} -
                          {{ selectHour.appointmentEndLabel }}
                        </td>
                      </tr>

                      <tr v-if="userToBook.showServicePrice">
                        <td style="text-align: center">
                          {{ $t("bookingForm.total") }}
                        </td>
                        <td style="text-align: center">
                          <span class="serviceDetail">{{
                            $t("currency", { amount: totalPrice })
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <v-card
            color="white"
            class="hidden-sm-and-down pa-4"
            style="margin-top: -11px"
            flat
          >
            <div v-if="selectedServices == null">
              <h2>{{ $t("bookingForm.selectService") }}</h2>
            </div>

            <div class="hidden-sm-and-down">
              <v-col justify="center" align="center">
                <v-row justify="center">
                  <v-card-text
                    v-if="
                      userToBook.sendAutomaticallyReminder == false &&
                      userToBook.sendAutomaticallyReceipt == false
                    "
                    class="checkBoxToolTip"
                    >{{ $t("bookingForm.checkboxTooltipBoth") }}
                  </v-card-text>
                  <v-card-text
                    v-if="
                      userToBook.sendAutomaticallyReminder == false &&
                      userToBook.sendAutomaticallyReceipt == true
                    "
                    class="checkBoxToolTip"
                    >{{ $t("bookingForm.checkboxTooltipReminder") }}
                  </v-card-text>
                  <v-card-text
                    v-if="
                      userToBook.sendAutomaticallyReminder == true &&
                      userToBook.sendAutomaticallyReceipt == false
                    "
                    class="checkBoxToolTip"
                    >{{ $t("bookingForm.checkboxTooltipReceipt") }}
                  </v-card-text>

                  <div
                    v-if="userToBook.customerReceiptAndReminderMode == 'email'"
                  >
                    <v-checkbox
                      v-if="userToBook.sendAutomaticallyReminder == false"
                      v-model="emailReminder"
                      :label="$t('bookingForm.emailReminder')"
                    ></v-checkbox>

                    <v-checkbox
                      v-if="userToBook.sendAutomaticallyReceipt == false"
                      v-model="emailReceipt"
                      :label="$t('bookingForm.emailReceipt')"
                    ></v-checkbox>
                  </div>

                  <div
                    v-if="userToBook.customerReceiptAndReminderMode == 'sms'"
                  >
                    <v-checkbox
                      v-if="userToBook.sendAutomaticallyReminder == false"
                      v-model="smsReminder"
                      :label="$t('bookingForm.smsReminder')"
                    ></v-checkbox>

                    <v-checkbox
                      v-if="userToBook.sendAutomaticallyReceipt == false"
                      v-model="smsReceipt"
                      :label="$t('bookingForm.smsReceipt')"
                    ></v-checkbox>
                  </div>

                  <v-checkbox
                    v-if="userToBook.activateEmailList"
                    v-model="signupNewsletter"
                    :label="$t('bookingForm.newsletterSignup')"
                  ></v-checkbox>

                  <v-card-text>
                    <v-btn color="#7CAD3E" dark @click="bookAppointment">{{
                      $t("bookingForm.bookAppointment")
                    }}</v-btn>
                  </v-card-text>

                  <v-card-text>
                    <span
                      style="color: rgb(150 150 150)"
                      class="text-center py-1 text--accent-1"
                      v-if="userToBook.hoursToCancel > 0"
                      >{{ $t("bookingForm.agreeWithConsent") }}</span
                    >
                  </v-card-text>
                </v-row>
              </v-col>

              <v-textarea
                full-width
                height="100"
                outlined
                no-resize
                :label="$t('bookingForm.addComment')"
                rows="3"
                row-height="20"
                v-model="comment"
              >
              </v-textarea>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="hidden-md-and-up pa-3">
      <v-card width="100%" flat color="#ECECF3">
        <v-card-text class="py-0">
          <v-textarea
            full-width
            height="50"
            outlined
            filled
            no-resize
            background-color="white"
            :label="$t('bookingForm.addComment')"
            rows="3"
            hide-details
            row-height="20"
            v-model="comment"
          >
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row justify="center" class="hidden-md-and-up pa-3">
      <v-card-text style="padding-top: 0">
        <v-card-text
          v-if="
            userToBook.sendAutomaticallyReminder == false &&
            userToBook.sendAutomaticallyReceipt == false
          "
          class="checkBoxToolTip px-0"
          >{{ $t("bookingForm.checkboxTooltipBoth") }}</v-card-text
        >
        <v-card-text
          v-if="
            userToBook.sendAutomaticallyReminder == false &&
            userToBook.sendAutomaticallyReceipt == true
          "
          class="checkBoxToolTip px-0"
          >{{ $t("bookingForm.checkboxTooltipReminder") }}</v-card-text
        >
        <v-card-text
          v-if="
            userToBook.sendAutomaticallyReminder == true &&
            userToBook.sendAutomaticallyReceipt == false
          "
          class="checkBoxToolTip px-0"
          >{{ $t("bookingForm.checkboxTooltipReceipt") }}</v-card-text
        >

        <div v-if="userToBook.customerReceiptAndReminderMode == 'email'">
          <v-checkbox
            class="my-0"
            v-model="emailReminder"
            v-if="userToBook.sendAutomaticallyReminder == false"
            :label="$t('bookingForm.emailReminder')"
          ></v-checkbox>

          <v-checkbox
            class="my-0"
            v-model="emailReceipt"
            v-if="userToBook.sendAutomaticallyReceipt == false"
            :label="$t('bookingForm.emailReceipt')"
          ></v-checkbox>
        </div>

        <div v-if="userToBook.customerReceiptAndReminderMode == 'sms'">
          <v-checkbox
            class="my-0"
            v-model="smsReminder"
            v-if="userToBook.sendAutomaticallyReminder == false"
            :label="$t('bookingForm.smsReminder')"
          ></v-checkbox>

          <v-checkbox
            class="my-0"
            v-model="smsReceipt"
            v-if="userToBook.sendAutomaticallyReceipt == false"
            :label="$t('bookingForm.smsReceipt')"
          ></v-checkbox>
        </div>

        <v-checkbox
          class="my-0"
          v-if="userToBook.activateEmailList"
          v-model="signupNewsletter"
          :label="$t('bookingForm.newsletterSignup')"
        ></v-checkbox>
      </v-card-text>

      <v-btn width="80%" color="#7CAD3E" dark @click="bookAppointment">{{
        $t("bookingForm.bookAppointment")
      }}</v-btn>

      <span
        style="color: rgb(150 150 150); margin: auto"
        class="text-center py-1 text--accent-1"
        v-if="userToBook.hoursToCancel > 0"
        >{{ $t("bookingForm.agreeWithConsent") }}</span
      >
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.checkBoxToolTip {
  font-size: 0.9rem;
  font-weight: 600;
}
.booking-form {
  float: lefT;
  width: 80%;
  background: white;
  height: auto;
  padding: 20px;
  input[type="text"],
  select,
  input[type="number"],
  input[type="number"] {
    width: 46.7%;
    float: left;
    height: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 4px 1.2%;
    border: none;
    border-radius: 4px;
    font-size: 19px;
    box-shadow: 0px 0px 2px 1px #0000002d;
    color: #333;
    font-weight: bold;
    padding: 25px 16px;
  }
  select {
    padding: 8px 16px;
    height: 50px;
    color: #757575;
    cursor: pointer;
  }
  button {
    padding: 9px 20px;
    margin: 14px 1.2%;
    top: 10px;
    float: left;
  }
}

@media only screen and (max-width: 800px) {
  .bookingpage-calendar-wrapper {
    margin: 0;
  }
  .booking-form {
    width: 93%;
    margin: 0px 2.5% 10px 2.5%;
    input[type="text"],
    select,
    input[type="number"] {
      width: 100%;
    }
  }
}
</style>
