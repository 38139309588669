<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, Mutation } from "vuex-class";

import CustomerInformationForm from "./customerInformationForm.vue";
import CustomAccountCard from "./CustomerAccountCard.vue";
import Loader from "@/components/global/Loader.vue";
import { AppointmentInfo } from "../../store/appointments/appointmentTypings";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
} from "@/types/formatDateHelper";
import { DialogType, IDialog } from "@/store/dialog";
import SelectService from "./ChooseService.vue";
import Calendar from "@/components/calendar/Calendar.vue";
import { IUser } from "@/store/user";
import CustomerAccountForm from "./CustomerAccountForm.vue";
import {
  IAppointmentChangeAsCustomAccount,
  ICustomerDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { cancelAppointmentByCustomerAccount } from "@/Utilities/endpoints";
import { IAvailableHour } from "@/store/workingHours";
import { IServiceBookingDetail } from "@/store/appointments/appointmentTypes";

@Component({
  name: "customer-account",
  components: {
    CustomerAccountForm,
    Calendar,
    SelectService,
    Loader,
    CustomAccountCard,
    CustomerInformationForm,
  },
})
export default class CustomerAccount extends Vue {
  @Action public fetchCustomerByToken: (
    customerToken: any
  ) => Promise<ICustomerDTO>;

  @Action public getCustomerAccountAppointments: Function | undefined;
  @Action public addToDialogQueue: Function | undefined;
  @Action public getUserAsAnonymous: Function | undefined;
  @Action changeAppointmentAsCustomerAccount: (
    appointment: IAppointmentChangeAsCustomAccount
  ) => Promise<any>;
  @Mutation public setBookingStep: Function;

  @Getter userToBook: IUser | undefined;
  @Getter customerAccount: any;

  @Getter loggedInCustomer: ICustomerDTO | undefined;
  @Getter public selectHour: IAvailableHour;

  @Getter public selectedDate: string | undefined;

  public loadingAppointments: boolean = false;
  public customerAccountAppointments: Array<AppointmentInfo> = [];

  public phoneNumber: string = "";
  public password: string = "";

  public hasSelectedNewUser: boolean = false;

  public isRegisteringNewUser: boolean = false;

  public editingAppointment: AppointmentInfo | null = null;
  public selectedServices: number[] = null;
  private customerToken: string | null = null;

  public serviceBookingsDetails: IServiceBookingDetail[] = [];
  public selectedServiceDetails: IServiceBookingDetail[] = [];

  public phoneRules = [
    (v) => !!v || this.$t("customerAccount.phoneRequired").toString(),
    (v) => /^\d+$/.test(v) || this.$t("customerAccount.phoneValid").toString(),
    (v) => v.length == 8 || this.$t("customerAccount.phoneValid").toString(),
  ];

  public codeRules = [
    (v) => !!v || this.$t("customerAccount.codeRequired").toString(),
    (v) => /^\d+$/.test(v) || this.$t("customerAccount.codeValid").toString(),
    (v) => v.length == 3 || this.$t("customerAccount.codeValid").toString(),
  ];

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  async created() {
    this.$store.commit(
      "setStepperTitle",
      this.$t("customerAccount.myPage").toString()
    );

    this.hasSelectedNewUser = true;
    let vm = this;

    await this.getUserAsAnonymous(this.$route.params.company);
    console.log("User: " + this.userToBook.userId);

    this.customerToken = localStorage.getItem("customerToken");
    this.phoneNumber = localStorage.getItem("customerTokenPhoneNumber");

    let phoneFromQuery: string = this.getParameterByName("p");
    let codeFromQuery: string = this.getParameterByName("c");

    if (phoneFromQuery != null) {
      this.phoneNumber = phoneFromQuery;
    }

    if (phoneFromQuery != null) {
      this.customerToken = codeFromQuery;
    }

    if (this.customerToken != null && this.phoneNumber != null) {
      let res = this.fetchCustomerByToken({
        customerToken: this.customerToken,
        userId: this.userToBook.userId,
        phoneNumber: this.phoneNumber,
      });
    }
  }

  async loginWithToken() {
    try {
      let res = this.fetchCustomerByToken({
        customerToken: this.customerToken,
        userId: this.userToBook.userId,
        phoneNumber: this.phoneNumber,
      });
    } catch (e) {
      this.$store.commit("addToDialogQueue", {
        text: this.$t("customerAccount.invalidCode").toString(),
      });
    }
  }

  formatDateStringHour(date: Date) {
    if (date == null) {
      return "";
    }

    let toDateObject = new Date(date as any);

    return (
      formatDate(toDateObject, FormatSpaceType.DOT) +
      " " +
      formatTime(toDateObject.getHours(), toDateObject.getMinutes())
    );
  }

  async cancelAppointment(appointment: AppointmentInfo) {
    let vm = this;
    let dialog: IDialog = {
      text: this.$t("customerAccount.confirmCancel").toString(),
      header:
        appointment.day + "." + appointment.month + "." + appointment.year,
      type: DialogType.Choice,
      action: async function () {
        // vm.loadingAppointments = true;
        try {
          let cancelled = await cancelAppointmentByCustomerAccount(
            appointment.id,
            vm.customerToken,
            vm.phoneNumber,
            vm.userToBook.userId
          );
          await vm.fetchCustomerByToken({
            customerToken: vm.customerToken,
            userId: vm.userToBook.userId,
            phoneNumber: vm.phoneNumber,
          });

          let dialog: IDialog = {
            text: vm.$t("customerAccount.appointmentCancelled").toString(),
          };
          vm.addToDialogQueue(dialog);
          vm.isEditingAppointment = false;
          vm.loadingAppointments = false;
        } catch (e) {
          let dialog: IDialog = {
            text: vm
              .$t("customerAccount.cancelLimit")
              .toString()
              //@ts-ignore
              .replace("{hours}", vm.userToBook.hoursToCancel),
          };
          vm.addToDialogQueue(dialog);
        }
        vm.customerAccountAppointments =
          await vm.getCustomerAccountAppointments(vm.customerAccount);
        vm.loadingAppointments = false;
        vm.isEditingAppointment = false;
      },
    };

    this.addToDialogQueue(dialog);
  }
  moveAppointment(appointment: AppointmentInfo) {
    //@ts-ignore
    this.selectedServices = appointment.serviceIds;
    this.editingAppointment = appointment;

    //TODO: DRY with bookingForm.vue, we do the same lgoic. extrac as shared function

    console.log("Selected services: " + this.selectedServices);
    let serviceBookings: any = this.selectedServices.map((x: any) => {
      let extraServiceIds = [];
      if (x.children != null) {
        extraServiceIds = x.children.filter((c) => {
          return c.selected;
        });
      }

      return {
        serviceId: x.id,
        extraServiceIds: extraServiceIds.map((x) => x.id),
      };
    });

    let serviceBookingsDetails: IServiceBookingDetail[] =
      this.selectedServices.map((x: any) => {
        let children = [];
        if (x.children != null) {
          children = x.children.filter((c) => {
            return c.selected;
          });
        }

        return { service: x, children: children };
      });

    this.serviceBookingsDetails = serviceBookingsDetails;
    this.selectedServiceDetails = serviceBookings;
  }

  get isEditingAppointment() {
    return this.editingAppointment != null;
  }

  set isEditingAppointment(value) {
    if (value == false) {
      this.editingAppointment = null;
      this.selectedServices = null;
    }
  }
  async onSelectedHourConfirm(selectedHour) {
    let a = this.selectHour.label.split(":"); // split it at the colons
    let hour = parseInt(a[0]);
    let minute = parseInt(a[1]);
    //@ts-ignore
    let currentSelectedDate: Date = new Date(Date.parse(this.selectHour.date));

    currentSelectedDate.setMonth(currentSelectedDate.getMonth() + 1);

    let appointmentUpdate: IAppointmentChangeAsCustomAccount = {
      year: currentSelectedDate.getFullYear(),
      month: currentSelectedDate.getMonth(),
      day: currentSelectedDate.getDate(),
      hour: hour,
      minute: minute,
      serviceIds: this.selectedServices,
      phoneNumber: this.phoneNumber,
      appointmentId: this.editingAppointment.id,
      adminId: this.userToBook.userId,
      customerToken: this.customerToken,
    };
    this.isEditingAppointment = false;
    let res = await this.changeAppointmentAsCustomerAccount(appointmentUpdate);
    let res2 = this.fetchCustomerByToken({
      customerToken: this.customerToken,
      userId: this.userToBook.userId,
      phoneNumber: this.phoneNumber,
    });
    let dialog: IDialog = {
      text: this.$t("customerAccount.appointmentChanged").toString(),
    };
    this.addToDialogQueue(dialog);
    // this.customerAccountAppointments = await this.getCustomerAccountAppointments(this.customerAccount);
    this.cancelEditing();
  }

  async onSelectedHour(selectedHour) {
    let vm = this;
    //@ts-ignore
    let currentSelectedDate: Date = new Date(Date.parse(this.selectHour.date));

    let dialog: IDialog = {
      text: this.$t("customerAccount.changeTime")
        .toString()
        .replace("{date}", formatDate(currentSelectedDate))
        .replace("{time}", this.selectHour.label),
      type: DialogType.Choice,
      action: function () {
        vm.onSelectedHourConfirm(selectedHour);
      },
    };

    this.$store.commit("addToDialogQueue", dialog);
  }
  cancelEditing() {
    this.editingAppointment = null;
    //this.selectedService = null;
  }

  onFetchedCustomerInformation(customerInfo) {
    this.isRegisteringNewUser = true;
  }

  enterBooking() {
    this.setBookingStep(1);
    this.$router.push("/" + this.userToBook.bookingLink);
  }
}
</script>

<template>
  <div
    v-if="userToBook != null && loggedInCustomer != null"
    class="customer-account-wrapper"
  >
    <!-- I have disabled this because its too complex with extra services. the backend endpoint returns a list of ids for services
    But I need to return a different object which has id's and extra services
    I will fix this later. Actually, when I think about it, 
    ExtraServices isn't actually necessary. Because it doesn't actually matter for anyone
    Which service "belongs" to which. The most important thing is:
    1. The total duration, total price, and the names of the services. (what needs to be done)
    The provider of the service knows when to do the extra serice -->

    <!-- <v-dialog color="white" max-width="600" v-model="isEditingAppointment">
      <v-card color="white">
        <v-card-title>
          <v-btn @click="cancelEditing">{{
            $t("customerAccount.cancel")
          }}</v-btn>
        </v-card-title>
        <v-card-text>
          <calendar
            @choosehour="onSelectedHour"
            :selected-services="selectedServiceDetails"
            :user="userToBook"
            :adminId="userToBook.userId"
            :userBooking="true"
            :propAppointmentDistribution="
              userToBook.appointmentDistributionInMinutes
            "
            :serviceBookingsDetails="serviceBookingsDetails"
          ></calendar>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- For now we're disbaled this login feature because it's not used anywhere. Maybe eventually, but I'd prefer everything to be automatic. No login. -->
    <div v-if="loggedInCustomer == null">
      <v-card class="mb-10">
        <v-card-text>
          <v-text-field
            clearable
            v-model="phoneNumber"
            :rules="phoneRules"
            :label="$t('customerAccount.phoneNumber')"
            required
            max-width="300px"
            outlined
            type="phone"
          ></v-text-field>

          <v-text-field
            clearable
            v-model="customerToken"
            :rules="codeRules"
            :label="$t('customerAccount.smsCode')"
            required
            max-width="300px"
            outlined
            type="phone"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="loginWithToken">{{
            $t("customerAccount.login")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div v-if="loggedInCustomer != null" class="account-content">
      <v-row class="fill-height ma-0" justify="center" align="center">
        <v-col cols="12" md="10" lg="8" class="pa-0">
          <v-card class="account-card">
            <loader v-if="loadingAppointments"></loader>
            <div v-else>
              <v-card-title v-if="loggedInCustomer != null">{{
                $t("customerAccount.yourAppointments")
              }}</v-card-title>

              <v-card-text>
                {{
                  $t("customerAccount.cancellationPolicy")
                    .toString()
                    .replace("{hours}", userToBook.hoursToCancel)
                }}
                <div v-if="userToBook.cancellationBoxText">
                  <p>{{ userToBook.cancellationBoxText }}</p>
                </div>
              </v-card-text>

              <v-simple-table width="100%" height="300">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("customerAccount.service") }}
                      </th>
                      <th class="text-left">
                        {{ $t("customerAccount.time") }}
                      </th>
                      <!-- <th class="text-left">
                        {{ $t("customerAccount.move") }}
                      </th> -->
                      <th class="text-left">
                        {{ $t("customerAccount.cancel") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="appointment in loggedInCustomer.appointments"
                      :key="appointment.id"
                    >
                      <td>{{ appointment.appointmentName }}</td>
                      <td>
                        {{
                          formatDateStringHour(
                            new Date(
                              appointment.year,
                              appointment.month - 1,
                              appointment.day,
                              appointment.hour,
                              appointment.minute,
                              0
                            )
                          )
                        }}
                      </td>
                      <!-- <td>
                        <v-btn
                          v-if="appointment.canCancel"
                          @click="moveAppointment(appointment)"
                          ><v-icon>mdi-calendar-arrow-right</v-icon></v-btn
                        >
                      </td> -->
                      <td>
                        <v-btn
                          class="hidden-xs-only"
                          color="error"
                          dark
                          v-if="appointment.canCancel"
                          @click="cancelAppointment(appointment)"
                          ><v-icon>mdi-cancel</v-icon
                          >{{ $t("customerAccount.cancel") }}</v-btn
                        >
                        <v-btn
                          class="hidden-sm-and-up"
                          color="error"
                          dark
                          v-if="appointment.canCancel"
                          @click="cancelAppointment(appointment)"
                          ><v-icon>mdi-cancel</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <v-card-actions>
              <v-btn
                class="mt-3"
                rounded
                style="height: 42px"
                outlined
                color="#5C6BC0"
                @click="enterBooking"
                dark
              >
                <v-icon>note_add</v-icon>
                {{ $t("customerAccount.bookNewAppointment") }}</v-btn
              >
              <br />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.customer-account-wrapper {
  min-height: 100vh;
  width: 100%;
  background-color: #ececf3;
  display: flex;
  align-items: center;
}

.account-content {
  width: 100%;
}

.account-card {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .account-card {
    height: 100vh;
    border-radius: 0 !important;
  }

  .customer-account-wrapper {
    background-color: white;
  }
}
</style>
