import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { APIURL2 } from "@/main";
import { actionStringSnackbar } from "@/store/snackbar";
import { DialogMutations, IDialog } from "./dialog";
import { mutationStringSnackbar } from "./snackbar";
import {
  getCustomerAccountByPhoneNumber,
  getCustomerAccountByPhoneNumberAndPassword,
  postCustomerAccount,
  putCustomerAccount,
  sendNewPassordToCustomerAccount,
  sendPasswordCode,
} from "@/Utilities/endpoints";
import {
  ICustomerAccountAnonymousRequest,
  ICustomerAccountRequest,
  IEditCustomerAccountDTO,
  IPostCustomerAccountDTO,
  IResetPasswordByCode,
} from "@/types/AutoGenerated/reinforcedTypings";

// Interface
export interface customerAccountState {
  customerAccount: ICustomerAccount | null;
  bookingStep: number;
}

export interface ICustomerAccount {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

export enum CustomerAccountsMutations {
  SetCustomerAccount = "setCustomerAccount",
  SetBookingStep = "setBookingStep",
}

export enum CustomerAccountsActions {
  POST_CUSTOMER_ACCOUNT = "postCustomerAccount",
  GET_CUSTOMER_ACCOUNT_BY_PHONE_AND_PASSWORD = "getCustomerAccountByPhoneNumberAndPassword",
}

export const state: customerAccountState = {
  customerAccount: null,
  bookingStep: 0, // or any default value you prefer
};

export const getters: GetterTree<customerAccountState, any> = {
  customerAccount: (state) => state.customerAccount,
};

export const mutations: MutationTree<customerAccountState> = {
  setCustomerAccount(
    state: customerAccountState,
    payload: IPostCustomerAccountDTO
  ) {
    state.customerAccount = payload;
  },
};

export const actions: ActionTree<customerAccountState, any> = {
  getCustomerAccountByPhoneNumber(
    { commit },
    req: ICustomerAccountAnonymousRequest
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await getCustomerAccountByPhoneNumber(req);
        resolve(customer);
      } catch (e) {
        reject(new Error("Could not load customers"));
      }
    });
  },
  async getCustomerAccountByPhoneNumberAndPassword(
    { commit },
    login: ICustomerAccountRequest
  ): Promise<ICustomerAccount> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await getCustomerAccountByPhoneNumberAndPassword(login);
        commit(CustomerAccountsMutations.SetCustomerAccount, customer);
        return resolve(customer);
      } catch (e) {
        let dialog: IDialog = {
          text: "Feil passord",
        };
        commit(DialogMutations.AddToDialogQueue, dialog);
        reject(e);
      }
    });
  },
  async sendNewPassordToCustomerAccount(
    { commit },
    req: ICustomerAccountAnonymousRequest
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await sendNewPassordToCustomerAccount(req);
        return resolve(customer);
      } catch (e) {
        reject(e);
      }
    });
  },

  async logoutCustomerAccount(
    { commit },
    phoneNumber: Partial<IPostCustomerAccountDTO>
  ): Promise<any> {
    commit(CustomerAccountsMutations.SetCustomerAccount, null);
    commit(CustomerAccountsMutations.SetBookingStep, 1);
  },
  async putCustomerAccount(
    { commit },
    payload: IEditCustomerAccountDTO
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await putCustomerAccount(payload);
        commit(CustomerAccountsMutations.SetCustomerAccount, customer);

        commit(
          mutationStringSnackbar.setSnackbar,
          "Du har redigert profilen din."
        );
        return resolve(customer);
      } catch (e) {
        reject(e);
      }
    });
  },

  async sendPasswordCode(
    { commit },
    payload: IResetPasswordByCode
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await sendPasswordCode(payload);
        return resolve(customer);
      } catch (e) {
        let dialog: IDialog = {
          text: "Feil kode.",
        };
        commit(DialogMutations.AddToDialogQueue, dialog);
        reject(e);
      }
    });
  },
  async postCustomerAccount(
    { commit },
    customerAccount
  ): Promise<IPostCustomerAccountDTO> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await postCustomerAccount(customerAccount);
        commit(CustomerAccountsMutations.SetCustomerAccount, customer);
        resolve(customer);
      } catch (e) {
        reject(new Error("Could not create customer account: " + e));
      }
    });
  },
};

export const customerAccounts = {
  actions,
  mutations,
  getters,
  state,
};
