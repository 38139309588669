import axios from "axios";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { APIURL2 } from "@/main";
import { ICustomFieldDTO } from "@/types/AutoGenerated/reinforcedTypings";

export interface userState {
  user: IUser | null;
  userToBook: IUser | null;
  stepperTitle: string;
  bookingStep: number;
  customFields: Array<ICustomFieldDTO>;
}

// Interface Model
export interface IUser {
  userId: string;
  companyId: number;
  companyRole: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  gender: string;
  appointmentDistributionInMinutes: number;
  profilePictureUrl?: string;
  companyName: string;
  subscriptionType?: number | string;
  treatmentAdress: string;
  sector: number;
  smsVerification: boolean;
  showOccupiedHoursForAnonymousBooking: boolean;
  automaticAddCustomers: boolean;
  abilityToCancel: boolean;
  hoursToCancel: number;
  bookingLink: string;
  organizationNumber: number;
  bankAccount: number;
  useKID: boolean;
  kidLength: number;
  kidMod: number;
  taxPercentage: number;
  dueDateLength: number;
  minumumHourBeforeBooking: number;
  maxDaysInAdvanceForBooking: number;
}

export const enum mutationStringUser {
  setUser = "setUser",
  updateUser = "updateUser",
  setUserToBook = "setUserToBook",
}

export const enum actionStringUser {
  getCurrentUser = "getCurrentUser",
  UPDATE_USER = "updateUser",
}

export const state: userState = {
  user: null,
  userToBook: null,
  stepperTitle: "Velg tjeneste",
  bookingStep: 1,
  customFields: null,
};

export const getters: GetterTree<userState, any> = {
  user: (state) => state.user,
  userToBook: (state) => state.userToBook,
  stepperTitle: (state) => state.stepperTitle,
  bookingStep: (state) => state.bookingStep,
  customFields: (state) => state.customFields,
};

export const mutations: MutationTree<userState> = {
  setUser(state, payload: IUser) {
    state.user = payload;
  },
  setCustomFields(state, payload: any) {
    state.customFields = payload;
  },
  setUserToBook(state, payload: any) {
    state.userToBook = payload;
  },
  setStepperTitle(state, payload: any) {
    state.stepperTitle = payload;
  },
  setBookingStep(state, payload: any) {
    state.bookingStep = payload;
  },
  goBackBookingStep(state, payload: number) {
    state.bookingStep -= payload;

    if (state.bookingStep == 1) {
      state.stepperTitle = "Velg tjeneste";
    }
    if (state.bookingStep == 2) {
      state.stepperTitle = "Velg tidspunkt";
    }
    if (state.bookingStep == 3) {
      state.stepperTitle = "Din informasjon";
    }
    if (state.bookingStep == 4) {
      state.stepperTitle = "Bestilling";
    }
  },
};

// @ts-ignore
export const actions: ActionTree<userState, any> = {
  // @ts-ignore
  async fetchInitialUserInfo({ dispatch, commit }): Promise<IUser> {
    const now: Date = new Date();

    const actualMonth = now.getMonth() + 1;

    let loadedWorkingHours = dispatch("getAllWorkingHours");
    let loadedWorkingDays = dispatch("getAllWorkingDays");

    let user = dispatch("getCurrentUser");
    let userSubscription = dispatch("getUserSubscription");

    Promise.all([user]).then((values) => {
      commit("setHasResolvedUser", true);
      return new Promise((resolve, reject) => {
        resolve(user);
      });
    });
  },

  getUserAvailableHoursCache({ commit }, payload): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "appointment/availableHours/cache/", payload)
        .then((res) => {
          commit("loadPreloadedAvailableHours", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCustomFieldsAsAnonymous({ commit }, adminId: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "CustomField/asAnonymous/" + adminId)
        .then((res) => {
          commit("setCustomFields", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async getUserAsAnonymous({ commit }, adminId: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "User/" + adminId)
        .then((res) => {
          commit(mutationStringUser.setUserToBook, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getUserByNameAsAnonymous({ commit }, companyName: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "User/ByUser/" + companyName)
        .then((res) => {
          commit(mutationStringUser.setUserToBook, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export const user = {
  actions,
  mutations,
  getters,
  state,
};
