import {DialogType} from "../../store/dialog";

<script lang="ts">
"use strict";

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { actionStringAppointments } from "@/store/appointments/appointments";
import { IAppointmentForm } from "@/store/appointments/appointmentTypings";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { DialogType, IDialog } from "@/store/dialog";
import { CustomerAccountsActions } from "@/store/customerAccounts";
import CustomerAccountForm from "./CustomerAccountForm.vue";
import {
  ICustomerAccountAnonymousRequest,
  ICustomerAccountDTO,
  ICustomerAccountRequest,
  IPostCustomerAccountDTO,
  IResetPasswordByCode,
} from "@/types/AutoGenerated/reinforcedTypings";
import { IUser } from "@/store/user";
import {
  IappointmentType,
  IAvailableHoursRequest,
} from "@/store/appointments/appointmentTypes";

@Component({
  name: "customer-information-form",
  components: { CustomerAccountForm },
})
export default class customerInformationForm extends Vue {
  @Prop(String) public userId: string | null;
  @Prop({ default: null }) appointmentTypes: IappointmentType[];
  @Prop({ default: false }) mustLogin: boolean;
  @Prop({ default: "Enter your phone number" }) title: string;
  @Prop({ default: "Continue" }) loginButton: string;

  @Action postAuditLog: Function;

  public hasSelectedNewUser: boolean = false;
  public hasSelectedExistingUser: boolean = false;

  public comment: string = "";
  public showResetPasswordField: boolean = false;

  public password: string = "";
  public phoneNumber: string = "";
  public phoneNumberPassword: string = "";

  public phoneValid: boolean = false;

  public resetNewPassword: string = "";
  public passwordResetCode: string = "";

  @Action public bookAppointmentAsCustomer: Function;
  @Action(actionStringAppointments.BOOK_APPOINTMENT_AS_ANONYMOUS)
  bookAppointmentAsAnonymous: (appointment: IAppointmentForm) => Promise<void>;
  @Action public getAppointmentTypesAsCustomer: Function;

  @Action("getCustomerAccountByPhoneNumber") getCustomerAccountByPhoneNumber: (
    appointment: ICustomerAccountAnonymousRequest
  ) => Promise<any>;
  @Action(CustomerAccountsActions.GET_CUSTOMER_ACCOUNT_BY_PHONE_AND_PASSWORD)
  getCustomerAccountByPhoneNumberAndPassword: (
    appointment: ICustomerAccountRequest
  ) => Promise<any>;

  @Action public postSnackbarMessage: Function;
  @Action(CustomerAccountsActions.POST_CUSTOMER_ACCOUNT) postCustomerAccount: (
    appointment: IPostCustomerAccountDTO
  ) => Promise<any>;
  @Action sendNewPassordToCustomerAccount: (
    appointment: ICustomerAccountAnonymousRequest
  ) => Promise<any>;
  @Action sendPasswordCode: (appointment: IResetPasswordByCode) => Promise<any>;

  @Action public addToDialogQueue: Function;

  @Mutation setCustomerAccount: (
    customerAccountDTO: Partial<ICustomerAccountDTO>
  ) => Promise<any>;

  @Getter public appointmentType: IappointmentType;
  @Getter public selectHour: string;
  @Getter public selectedDate: IAvailableHoursRequest;

  @Getter public userToBook: IUser;
  @Getter public customerAccount: ICustomerAccountDTO;

  public passwordRules = [];
  public codeRules = [];
  public phoneRules = [];

  mounted() {
    this.passwordRules = [
      (v) => !!v || this.$t("bookingForm.passwordRequired").toString(),
      (v) => v.length > 5 || this.$t("bookingForm.passwordLength").toString(),
    ];

    this.codeRules = [
      (v) => !!v || this.$t("customerAccount.codeRequired").toString(),
      (v) => v.length === 5 || this.$t("customerAccount.codeValid").toString(),
    ];

    this.phoneRules = [
      (v) => !!v || this.$t("customerAccount.phoneRequired").toString(),
      (v) =>
        /^\d+$/.test(v) || this.$t("customerAccount.phoneValid").toString(),
      (v) => v.length == 8 || this.$t("customerAccount.phoneValid").toString(),
    ];
  }

  public async saveCustomerAccountToState(
    customerAccountUpdate: Partial<IPostCustomerAccountDTO>
  ): Promise<void> {
    this.hasSelectedNewUser = false;
    this.hasSelectedExistingUser = false;

    this.setCustomerAccount(customerAccountUpdate);

    this.$emit("fetched", customerAccountUpdate);
  }
  public async createCustomerAccount(
    customerAccountUpdate: Partial<IPostCustomerAccountDTO>
  ): Promise<void> {
    let newAppointment: IPostCustomerAccountDTO = {
      firstName: customerAccountUpdate.firstName,
      lastName: "", // HARDCODED
      emailAddress: customerAccountUpdate.emailAddress,
      address: customerAccountUpdate.address,
      postalCode: customerAccountUpdate.postalCode,
      phoneNumber: customerAccountUpdate.phoneNumber,
      adminId: this.userId,
      password: customerAccountUpdate.password,
      city: customerAccountUpdate.city,
      ssn: customerAccountUpdate.ssn,
      dateOfBirth: customerAccountUpdate.dateOfBirth,
      gender: customerAccountUpdate.gender,
    };

    let response = await this.postCustomerAccount(newAppointment);

    this.hasSelectedNewUser = false;
    this.hasSelectedExistingUser = false;

    this.$emit("fetched", response);
  }

  public created(): void {
    this.getAppointmentTypesAsCustomer(this.userId);
  }

  async getCustomerAccountInfo() {
    let login: ICustomerAccountRequest = {
      password: this.password,
      login: this.phoneNumber,
      adminId: this.userToBook.userId,
    };
    let response = await this.getCustomerAccountByPhoneNumberAndPassword(login);
    this.$emit("fetched", response);
  }

  async attemptLoginCustomerAccount() {
    const phoneForm: any = this.$refs.phoneForm;
    if (!phoneForm.validate()) {
      return;
    }
    if (this.mustLogin) {
      let login: ICustomerAccountRequest = {
        password: this.phoneNumberPassword,
        login: this.phoneNumber,
        adminId: this.userToBook.userId,
      };
      let response = await this.getCustomerAccountByPhoneNumberAndPassword(
        login
      );
      this.$emit("fetched", response);
    } else {
      let req: ICustomerAccountAnonymousRequest = {
        login: this.phoneNumber,
        adminId: this.userToBook.userId,
      };

      let response = await this.getCustomerAccountByPhoneNumber(req);
      if (response == "New") {
        this.hasSelectedNewUser = true;
      }
      if (response == "Existing") {
        this.hasSelectedExistingUser = true;
      }
    }
  }

  async customAccountPassword() {
    let vm = this;
    let dialog: IDialog = {
      text: this.$t("customerAccount.sendNewPassword").toString(),
      type: DialogType.Choice,
      action: async function () {
        let req: ICustomerAccountAnonymousRequest = {
          login: vm.phoneNumber,
          adminId: vm.userToBook.userId,
        };

        await vm.sendNewPassordToCustomerAccount(req);
        vm.showResetPasswordField = true;
        let newDialog: IDialog = {
          text: vm.$t("customerAccount.enterCodeBelow").toString(),
        };
        vm.addToDialogQueue(newDialog);
      },
    };
    this.addToDialogQueue(dialog);
  }

  async attemptChangePassword() {
    const formReset: any = this.$refs.formReset;
    if (!formReset.validate()) {
      return;
    }
    let payload: IResetPasswordByCode = {
      adminId: this.userToBook.userId,
      code: this.passwordResetCode,
      login: this.phoneNumber,
      newPassword: this.resetNewPassword,
    };
    await this.sendPasswordCode(payload);

    let dialog: IDialog = {
      text: this.$t("customerAccount.passwordChanged").toString(),
    };
    this.addToDialogQueue(dialog);
    this.showResetPasswordField = false;
  }
}
</script>

<template>
  <div class="">
    <v-row justify="center" cols="auto">
      <v-col lg="6" xs="12" sm="8">
        <v-card
          style="max-width: 499px; margin: auto"
          class="choose-service-card pa-6"
          flat
          v-if="!userToBook.useCustomerAccounts"
        >
          <v-card-text>
            <customer-account-form
              data-cy="nextButton"
              :showPhone="userToBook.customerRequiredPhoneNumber"
              :showPassword="false"
              :buttonText="$t('customerAccount.next')"
              @submit="saveCustomerAccountToState"
            ></customer-account-form>
          </v-card-text>
        </v-card>

        <v-card flat v-if="userToBook.useCustomerAccounts">
          <v-card-title
            v-if="
              hasSelectedNewUser === false && hasSelectedExistingUser === false
            "
          >
            {{ title }}
          </v-card-title>

          <v-card-title v-if="hasSelectedExistingUser">
            {{ $t("customerAccount.enterPassword") }}
          </v-card-title>
          <v-card-title v-if="hasSelectedExistingUser">
            <v-form>
              <v-text-field
                v-model="password"
                :label="$t('customerAccount.password')"
                required
                outlined
                class="ml-1"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-title>

          <v-card-title
            v-if="
              hasSelectedNewUser === false && hasSelectedExistingUser === false
            "
          >
            <v-form ref="phoneForm" v-model="phoneValid" lazy-validation>
              <v-text-field
                v-model="phoneNumber"
                :rules="phoneRules"
                :label="$t('customerAccount.phoneNumber')"
                required
                outlined
                class="ml-1"
              ></v-text-field>

              <v-text-field
                v-if="mustLogin"
                v-model="phoneNumberPassword"
                :label="$t('customerAccount.password')"
                required
                outlined
                class="ml-1"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-title>

          <v-card-title
            v-if="
              hasSelectedNewUser === false && hasSelectedExistingUser === true
            "
          >
            <v-btn dark color="#5C6BC0" @click="getCustomerAccountInfo">{{
              $t("customerAccount.login")
            }}</v-btn>
            <br />
            <v-btn
              style="margin-top: 10px"
              v-if="showResetPasswordField === false"
              @click="customAccountPassword"
              >{{ $t("customerAccount.forgotPassword") }}</v-btn
            >
          </v-card-title>

          <v-card-title
            v-if="
              hasSelectedNewUser === false && hasSelectedExistingUser === false
            "
          >
            <v-btn
              data-cy="nextButton"
              dark
              color="#5C6BC0"
              @click="attemptLoginCustomerAccount"
              >{{ loginButton }}</v-btn
            >
            <br />
            <v-btn
              v-if="showResetPasswordField === false && mustLogin"
              @click="customAccountPassword"
              >{{ $t("customerAccount.forgotPassword") }}</v-btn
            >
          </v-card-title>

          <v-card-title
            v-if="
              (hasSelectedNewUser === false &&
                hasSelectedExistingUser === true) ||
              mustLogin
            "
          >
            <div v-if="showResetPasswordField">
              <v-form ref="formReset" lazy-validation>
                <v-text-field
                  v-model="passwordResetCode"
                  :label="$t('customerAccount.enterCode')"
                  :rules="codeRules"
                  required
                  outlined
                  class="ml-1"
                ></v-text-field>

                <v-text-field
                  v-model="resetNewPassword"
                  :rules="passwordRules"
                  :label="$t('customerAccount.newPassword')"
                  required
                  outlined
                  type="password"
                  class="ml-1"
                ></v-text-field>
              </v-form>

              <v-btn @click="attemptChangePassword">Ok</v-btn>
            </div>
          </v-card-title>

          <v-card-title v-if="hasSelectedNewUser">{{
            $t("customerAccount.createUser")
          }}</v-card-title>

          <div v-if="hasSelectedNewUser">
            <v-card-text>
              <customer-account-form
                :showPhone="!hasSelectedNewUser"
                :showPassword="hasSelectedNewUser"
                :buttonText="$t('customerAccount.createProfile')"
                @submit="createCustomerAccount"
              ></customer-account-form>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.booking-form {
  float: lefT;
  width: 80%;
  background: white;
  height: auto;
  padding: 20px;
  input[type="text"],
  select,
  input[type="number"],
  input[type="number"] {
    width: 46.7%;
    float: left;
    height: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 4px 1.2%;
    border: none;
    border-radius: 4px;
    font-size: 19px;
    box-shadow: 0 0 2px 1px #0000002d;
    color: #333;
    font-weight: bold;
    padding: 25px 16px;
  }
  select {
    padding: 8px 16px;
    height: 50px;
    color: #757575;
    cursor: pointer;
  }
  button {
    padding: 9px 20px;
    margin: 14px 1.2%;
    top: 10px;
    float: left;
  }
}

@media only screen and (max-width: 800px) {
  .bookingpage-calendar-wrapper {
    margin: 0;
  }
  .booking-form {
    width: 93%;
    margin: 0 2.5% 10px 2.5%;
    input[type="text"],
    select,
    input[type="number"] {
      width: 100%;
    }
  }

  .choose-service-card {
    @media (min-width: 1025px) {
      max-width: 499px;

      margin: auto;
    }
  }
}
</style>
