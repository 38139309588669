"use strict";

export enum ButtonSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large"
}

export  enum ModalContent{
    CONTACT = 'contact',
    SERVICES = 'services'
}

