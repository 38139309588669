
import { GetterTree, MutationTree, ActionTree } from 'vuex';



// Interface
export interface snackbarState {
    snackbar: SnackbarObject;
}

// Interface Model

export interface snackbar {
    snackbar:string | null;
}

export interface SnackbarObject {
    message: string;
    x: string;
    y: string;
    mode: string;
    color: string;
    isLoadingMessage: boolean;
}

// Mutation enums
export const enum mutationStringSnackbar {
    setSnackbar = 'setSnackbar',
}
export const enum actionStringSnackbar{
    postSnackbarMessage = 'postSnackbarMessage',
    postLoadingSnackbarMessage = 'postLoadingSnackbarMessage',
    postSuccessSnackbarMessage = "postSuccessSnackbarMessage",
    postErrorSnackbarMessage = "postErrorSnackbarMessage"
}

export const state = {
    snackbar: '',
};

export const getters: GetterTree<any, snackbarState> = {
    snackbar:state => state.snackbar
};

export const mutations: MutationTree<snackbarState> = {
    setSnackbar(state, payload:SnackbarObject) {
        state.snackbar = payload;
    },

};

export const actions: ActionTree<snackbarState, any> = {
    postSnackbarMessage({commit}, message: string): void {
        let newSnackbar : SnackbarObject = {
            message: message,
            mode: "",
            x: "right",
            y: "top",
            color: "#323232",
            isLoadingMessage: false
        };
        commit(mutationStringSnackbar.setSnackbar, newSnackbar);
    },

    postSuccessSnackbarMessage({commit}, message: string): void {
        let newSnackbar : SnackbarObject = {
            message: message,
            mode: "",
            x: "right",
            y: "top",
            color: "#4CAF50",
            isLoadingMessage: false
        };
        commit(mutationStringSnackbar.setSnackbar, newSnackbar);
    },

    postErrorSnackbarMessage({commit}, message: string): void {
        let newSnackbar : SnackbarObject = {
            message: message,
            mode: "",
            x: "right",
            y: "top",
            color: "#c44559",
            isLoadingMessage: false
        };
        commit(mutationStringSnackbar.setSnackbar, newSnackbar);
    },

    postLoadingSnackbarMessage({commit}, message: string): void {
        let newSnackbar : SnackbarObject = {
            message: message,
            mode: "",
            x: "right",
            y: "top",
            color: "#323232",
            isLoadingMessage: true
        };
        commit(mutationStringSnackbar.setSnackbar, newSnackbar);
    },


};

export const snackbarModule = {
    actions,
    mutations,
    getters,
    state,
};
