<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

import { IUser } from "../../store/user";
import Loader from "@/components/global/Loader.vue";
import Receipt from "@/views/user/receipt.vue";
import BookingForm from "./bookingForm.vue";
import CustomerInformationForm from "./customerInformationForm.vue";
import Calendar from "@/components/calendar/Calendar.vue";
import SelectService from "./ChooseService.vue";
import ReceiptPage from "@/views/user/receipt-page.vue";
import { IUserDTO } from "@/types/AutoGenerated/reinforcedTypings";
import {
  IappointmentType,
  IAvailableHoursRequest,
  IServiceBookingDetail,
} from "@/store/appointments/appointmentTypes";

import { Watch } from "vue-property-decorator";

@Component({
  name: "booking-stepper",
  components: {
    ReceiptPage,
    SelectService,
    Calendar,
    CustomerInformationForm,
    BookingForm,
    Receipt,
    Loader,
  },
})
export default class BookingStepper extends Vue {
  @Action public getUserAsAnonymous: Function;

  @Action public getUserByNameAsAnonymous: Function;

  @Getter selectHour: string;
  @Getter customerAccount: string;
  @Getter public userToBook: IUserDTO | null;

  @Mutation public setBookingStep: Function;
  @Mutation public setSelectedHour: Function;
  @Mutation public setSelectedDate: Function;

  userDetails: IUser = null;

  loaded: boolean = false;
  reminder: boolean = false;
  receipt: boolean = false;

  public customerInfo = null;
  public selectedServices: IappointmentType[] | null = null;
  public serviceBookingsDetails: any = null;

  @Action public getUserAvailableHoursCache: Function;

  public defaultColor: string = "#5C6BC0";

  public async created(): Promise<void> {}

  @Watch("userToBook")
  onUserToBookChange(newVal: IUserDTO) {
    if (newVal != null) {
      this.loaded = true;
      this.$store.commit("setBookingStep", 1);

      let now = new Date();

      let selectedDateToGetAppointmentsFrom: IAvailableHoursRequest = {
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear(),
        userId: this.userToBook.userId,
        //@ts-ignore //TODO: Fix type IAvailableHoursRequest or remove serviceBookings
        serviceBookings: [],
      };

      if (!this.userToBook.multipleServices) {
        this.getUserAvailableHoursCache(selectedDateToGetAppointmentsFrom);
      }
    }
  }

  //Selected multiple services
  onSelectServices(services: IappointmentType[]) {
    this.bookingStep = 2;
    this.$store.commit(
      "setStepperTitle",
      this.$t("bookingStepper.selectTime").toString()
    );

    let serviceBookings: any = services.map((x: any) => {
      let extraServiceIds = [];
      if (x.children != null) {
        extraServiceIds = x.children.filter((c) => {
          return c.selected;
        });
      }

      return {
        serviceId: x.id,
        extraServiceIds: extraServiceIds.map((x) => x.id),
      };
    });

    let serviceBookingsDetails: IServiceBookingDetail[] = services.map(
      (x: any) => {
        let children = [];
        if (x.children != null) {
          children = x.children.filter((c) => {
            return c.selected;
          });
        }

        return { service: x, children: children };
      }
    );

    this.selectedServices = serviceBookings;
    this.serviceBookingsDetails = serviceBookingsDetails;
  }

  onSelectedHour(hour) {
    //If we are logged in we skip the information form and straight to booking details
    this.bookingStep = 3;
  }

  onFetchedCustomerInformation(customerInfo) {
    this.customerInfo = customerInfo;
    this.$store.commit(
      "setStepperTitle",
      this.$t("bookingStepper.booking").toString()
    );
    this.bookingStep = 4;
  }

  onFinishedBooked(boxInfo) {
    this.bookingStep = 5;
    // this.selectedServices = null;
    //   this.setSelectedHour(null);
    //   this.setSelectedDate(null);
    this.reminder = boxInfo.reminder;
    this.receipt = boxInfo.receipt;
  }

  goBack() {
    this.$store.commit("goBackBookingStep", 1);
  }

  set bookingStep(value) {
    this.setBookingStep(value);
  }

  get bookingStep() {
    return this.$store.getters.bookingStep;
  }

  get showCalendar() {
    return this.$store.getters.bookingStep > 1;
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
</script>

<template>
  <div>
    <loader v-if="!loaded"></loader>

    <v-stepper
      color="#5C6BC0"
      flat
      v-model="bookingStep"
      v-if="userToBook != null"
      style="border-radius: 0"
    >
      <v-stepper-header class="hidden-sm-and-down">
        <v-btn
          class="mt-4 ml-2"
          @click="goBack"
          color="#5C6BC0"
          dark
          v-if="bookingStep > 1 && bookingStep !== 5"
          ><v-icon>arrow_back</v-icon> {{ $t("bookingStepper.back") }}</v-btn
        >
        <v-stepper-step
          :complete="bookingStep === 2"
          step="1"
          :color="defaultColor"
          >{{ $t("bookingStepper.selectService") }}</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step
          :complete="bookingStep === 3"
          step="2"
          :color="defaultColor"
          >{{ $t("bookingStepper.selectTime") }}</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step
          :complete="bookingStep === 4"
          step="3"
          :color="defaultColor"
          >{{ $t("bookingStepper.yourInformation") }}</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step
          :complete="bookingStep === 5"
          step="4"
          :color="defaultColor"
          >{{ $t("bookingStepper.confirm") }}</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step
          :complete="bookingStep === 5"
          step="5"
          :color="defaultColor"
          >{{ $t("bookingStepper.receipt") }}</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items flat>
        <v-row style="background: #ececf3; min-height: 800px">
          <v-col sm="12" class="pa-0 py-5">
            <v-stepper-content style="padding: 0" step="1">
              <select-service @onselect="onSelectServices"> </select-service>
            </v-stepper-content>

            <v-stepper-content style="padding: 0" step="2">
              <v-container fluid class="pt-0">
                <v-col>
                  <calendar
                    @choosehour="onSelectedHour"
                    :user="userToBook"
                    v-if="showCalendar"
                    :selectedServices="selectedServices"
                    :serviceBookingsDetails="serviceBookingsDetails"
                    :adminId="userToBook.userId"
                    :userBooking="true"
                    :propAppointmentDistribution="
                      userToBook.appointmentDistributionInMinutes
                    "
                  >
                  </calendar>
                </v-col>
              </v-container>
            </v-stepper-content>

            <v-stepper-content style="padding-top: 0px" step="3">
              <customer-information-form
                :userId="userToBook.userId"
                @fetched="onFetchedCustomerInformation"
                v-if="selectHour && bookingStep === 3"
              >
                ></customer-information-form
              >
            </v-stepper-content>

            <!--  CREATE APPOINTMENT TYPES  -->
            <v-stepper-content style="padding-top: 0px" step="4">
              <booking-form
                :selectedServices="selectedServices"
                :serviceBookingsDetails="serviceBookingsDetails"
                :userId="userToBook.userId"
                :customerAccount="customerInfo"
                v-if="
                  selectedServices != null && selectHour && bookingStep === 4
                "
                @finishedBooked="onFinishedBooked"
              >
              </booking-form>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card flat v-if="bookingStep === 5">
                <v-card-title>{{ $t("bookingStepper.thankYou") }}</v-card-title>
                <div
                  v-if="userToBook.customerReceiptAndReminderMode == 'email'"
                >
                  <v-card-subtitle v-if="receipt">{{
                    $t("bookingStepper.receiptEmail")
                  }}</v-card-subtitle>
                  <v-card-subtitle v-if="reminder">{{
                    $t("bookingStepper.reminderEmail")
                  }}</v-card-subtitle>
                </div>
                <div v-if="userToBook.customerReceiptAndReminderMode == 'sms'">
                  <v-card-subtitle v-if="receipt">{{
                    $t("bookingStepper.receiptSMS")
                  }}</v-card-subtitle>
                  <v-card-subtitle v-if="reminder">{{
                    $t("bookingStepper.reminderSMS")
                  }}</v-card-subtitle>
                </div>
                <v-card-text>
                  <receipt></receipt>
                  <receipt
                    :serviceBookingDetails="serviceBookingsDetails"
                  ></receipt>
                </v-card-text>
                <v-card-actions>
                  <receipt-page
                    :serviceBookingDetails="serviceBookingsDetails"
                  ></receipt-page>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-col>
        </v-row>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<style scoped></style>
