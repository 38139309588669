<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IDialog } from "@/store/dialog";
import CustomButton from "@/components/global/styledComponents/customButton.vue";

//* Component definition ************************************************************

@Component({
  name: "Dialog",
  components: { CustomButton },
})

//* Class ***************************************************************************
export default class Dialog extends Vue {
  @Prop({}) dialog: IDialog;

  private buttonEnabled: boolean = false;

  mounted() {
    let vm = this;
    if (this.dialog.actionButtonDelay != 0) {
      this.buttonEnabled = false;

      setTimeout(function () {
        vm.buttonEnabled = true;
      }, vm.dialog.actionButtonDelay * 1000);
    } else {
      this.buttonEnabled = true;
    }
  }

  closeNormalDialog() {
    this.$store.commit("closeNormalDialog", true);
  }
  closeNormalDialogCancel() {
    this.$store.commit("closeNormalDialog", false);
  }

  get isPng() {
    return this.dialog.icon.includes(".png");
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      <v-icon v-if="dialog.type === 0">info</v-icon>
      <v-icon v-if="dialog.type === 1">help</v-icon>

      <span class="title font-weight-light">{{ dialog.header }}</span>
    </v-card-title>
    <v-card-text>
      {{ dialog.text }}
    </v-card-text>

    <div v-if="dialog.type === 0">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeNormalDialog" :disabled="!buttonEnabled">Ok</v-btn>
        <!--<custom-button :disabled="!buttonEnabled" @custom-button-click="closeNormalDialog" :buttonText="'Ok'" ></custom-button>-->
      </v-card-actions>
    </div>
    <div v-if="dialog.type === 1">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeNormalDialogCancel" :disabled="!buttonEnabled">{{
          $t("dialog.cancel")
        }}</v-btn>
        <v-btn
          @click="closeNormalDialog"
          color="success"
          :disabled="!buttonEnabled"
          >{{ $t("dialog.confirm") }}</v-btn
        >
        <!--<custom-button :disabled="!buttonEnabled" @custom-button-click="closeNormalDialogCancel" :buttonText="'Avbryt'" :isFlat="true"></custom-button>-->
        <!--<custom-button :disabled="!buttonEnabled" @custom-button-click="closeNormalDialog" :buttonText="'Bekreft'" ></custom-button>-->
      </v-card-actions>
    </div>
    <div v-if="dialog.type === 2">
      <v-card-actions>
        <v-spacer></v-spacer>
        <custom-button
          :disabled="!buttonEnabled"
          @custom-button-click="closeNormalDialogCancel"
          :buttonText="$t('dialog.no')"
          :isFlat="true"
        ></custom-button>
        <custom-button
          :disabled="!buttonEnabled"
          @custom-button-click="closeNormalDialog"
          :buttonText="$t('dialog.yes')"
        ></custom-button>
      </v-card-actions>
    </div>
  </v-card>
</template>

<style scoped>
.dialog-icon {
  margin-right: 10px;
}
button {
  margin: 0 5px;
}
</style>
