<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { IUser } from "@/store/user";
import { atcb_init } from "add-to-calendar-button";
import { Prop } from "vue-property-decorator";
import { IAvailableHour } from "@/store/workingHours";

@Component({
  name: "receipt-page",
  components: {},
})
export default class receiptPage extends Vue {
  @Action getUserAsAnonymous: (userId: string) => Promise<IUser>;
  @Mutation setBookingStep;
  @Getter public userToBook;
  @Prop({}) serviceBookingDetails;
  @Getter public selectHour: IAvailableHour;

  mounted() {
    atcb_init();
  }
  public goBackToBookingPage(): void {
    let userId: string = this.$route.params.userId;
    let userPageRoute = "/booking/" + userId;
    this.$router.replace(userPageRoute);
  }

  public enterBooking() {
    this.setBookingStep(1);
    this.$router.push("/" + this.userToBook.bookingLink);
  }

  public enterMyPage() {
    this.setBookingStep(1);
    this.$router.push("/" + this.userToBook.bookingLink + "/min-side");
  }

  getTitle(service): string {
    let title = service.service.title;
    let index = 0;
    if (service.children && service.children.length > 0) {
      title += "(+ ";
      service.children.forEach((x) => {
        if (index != 0) {
          title += ", ";
        }
        title += x.title;
        index++;
      });
      title += ")";
    }
    return title;
  }

  //TODO: DRY for bookingform.vue
  formatAppointmentEnd(hourMinute: any, length: any) {
    var dat = new Date(),
      time = hourMinute.split(/\:|\-/g);
    dat.setHours(time[0]);
    dat.setMinutes(time[1]);

    dat.setMinutes(dat.getMinutes() + length);

    //@ts-ignore
    // let end = new Date(start.getTime() + (event.appointmentDuration*1000*60));
    let h = dat.getHours();
    let m = dat.getMinutes();
    return this.formatTimeCorrectly(h, m);
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return this.formatTime(hour, minute);
  }

  formatTime(hour: string | number, minute: string | number): string {
    let formattedTimeAsString: string = "";
    let localHour: number;
    let localMinute: number;

    //cast to number
    localHour = hour as number;
    localMinute = minute as number;

    //Add zero logic
    let addedZeroToHour = localHour < 10 ? "0" : "";
    let addedZeroToMinute = localMinute < 10 ? "0" : "";

    formattedTimeAsString =
      addedZeroToHour + localHour + ":" + addedZeroToMinute + localMinute;

    return formattedTimeAsString;
  }

  get totalDuration() {
    let total = 0;
    this.serviceBookingDetails.forEach((details: any) => {
      let dur = details.service.durationInMinutes;
      total += dur;
      let children = details.children;
      children.forEach((child) => {
        total += child.durationInMinutes;
      });
    });
    return total;
  }

  get eventConfig() {
    let eventTitle = this.$t("receiptPage.eventTitle", {
      companyName: this.userToBook.companyName,
    }).toString();

    let description =
      this.getTitle(this.serviceBookingDetails[0]) +
      "\n" +
      this.userToBook.phoneNumber;
    let sampleDate = "2022-02-21";

    let startDate = new Date(this.selectHour.date)
      .toISOString()
      .substring(0, sampleDate.length);

    //@ts-ignore
    let end = this.formatAppointmentEnd(
      //@ts-ignore
      this.selectHour.backendLabel,
      this.totalDuration
    );

    let endDate = startDate;
    //@ts-ignore
    let startTime = this.selectHour.backendLabel;
    let endTime = end;
    let location = this.userToBook.treatmentAdress;

    let locale = this.$i18n.locale;

    let timezone =
      this.userToBook.timeZone != null
        ? this.userToBook.timeZone
        : "Europe/Berlin";

    return {
      name: eventTitle,
      description: description,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      location: location,
      label: this.$t("receiptPage.addToCalendar").toString(),
      options: [
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
        "Yahoo",
      ],
      timeZone: timezone,
      trigger: "click",
      inline: true,
      listStyle: "modal",
      iCalFileName: "Reminder-Event",
      language: locale,
    };
  }
}
</script>

<template>
  <div>
    <div class="atcb" style="display: none">
      {{ eventConfig }}
    </div>
    <br />
    <v-btn
      class="mt-3 ml-2"
      dark
      color="#5C6BC0"
      style="height: 42px; width: 250px"
      @click="enterBooking"
    >
      <v-icon>note_add</v-icon>
      {{ $t("receiptPage.bookNewTime") }}
    </v-btn>

    <v-btn
      v-if="userToBook.useCustomerAccounts"
      class="mt-3"
      rounded
      style="height: 42px"
      outlined
      color="#5C6BC0"
      @click="enterMyPage"
      dark
    >
      <v-icon>account_circle</v-icon>
      {{ $t("receiptPage.goToMyPage") }}
    </v-btn>
  </div>
</template>

<style scoped></style>
