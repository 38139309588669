export const getCountryCodes = [
  {
    code: "af",
    name: "Afghanistan",
    dialCode: "+93",
    flag: "🇦🇫",
    phoneNumberLength: 10,
  },
  {
    code: "al",
    name: "Albania",
    dialCode: "+355",
    flag: "🇦🇱",
    phoneNumberLength: 8,
  },
  {
    code: "dz",
    name: "Algeria",
    dialCode: "+213",
    flag: "🇩🇿",
    phoneNumberLength: 8,
  },
  {
    code: "as",
    name: "American Samoa",
    dialCode: "+1684",
    flag: "🇦🇸",
    phoneNumberLength: 7,
  },
  {
    code: "ad",
    name: "Andorra",
    dialCode: "+376",
    flag: "🇦🇩",
    phoneNumberLength: 6,
  },
  {
    code: "ao",
    name: "Angola",
    dialCode: "+244",
    flag: "🇦🇴",
    phoneNumberLength: 9,
  },
  {
    code: "ai",
    name: "Anguilla",
    dialCode: "+1264",
    flag: "🇦🇮",
    phoneNumberLength: 7,
  },
  {
    code: "ag",
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    flag: "🇦🇬",
    phoneNumberLength: 7,
  },
  {
    code: "ar",
    name: "Argentina",
    dialCode: "+54",
    flag: "🇦🇷",
    phoneNumberLength: 10,
  },
  {
    code: "am",
    name: "Armenia",
    dialCode: "+374",
    flag: "🇦🇲",
    phoneNumberLength: 8,
  },
  {
    code: "au",
    name: "Australia",
    dialCode: "+61",
    flag: "🇦🇺",
    phoneNumberLength: 9,
  },
  {
    code: "at",
    name: "Austria",
    dialCode: "+43",
    flag: "🇦🇹",
    phoneNumberLength: 10,
  },
  {
    code: "az",
    name: "Azerbaijan",
    dialCode: "+994",
    flag: "🇦🇿",
    phoneNumberLength: 9,
  },
  {
    code: "bs",
    name: "Bahamas",
    dialCode: "+1242",
    flag: "🇧🇸",
    phoneNumberLength: 7,
  },
  {
    code: "bh",
    name: "Bahrain",
    dialCode: "+973",
    flag: "🇧🇭",
    phoneNumberLength: 8,
  },
  {
    code: "bd",
    name: "Bangladesh",
    dialCode: "+880",
    flag: "🇧🇩",
    phoneNumberLength: 11,
  },
  {
    code: "bb",
    name: "Barbados",
    dialCode: "+1246",
    flag: "🇧🇧",
    phoneNumberLength: 7,
  },
  {
    code: "by",
    name: "Belarus",
    dialCode: "+375",
    flag: "🇧🇾",
    phoneNumberLength: 9,
  },
  {
    code: "be",
    name: "Belgium",
    dialCode: "+32",
    flag: "🇧🇪",
    phoneNumberLength: 9,
  },
  {
    code: "bz",
    name: "Belize",
    dialCode: "+501",
    flag: "🇧🇿",
    phoneNumberLength: 7,
  },
  {
    code: "bj",
    name: "Benin",
    dialCode: "+229",
    flag: "🇧🇯",
    phoneNumberLength: 8,
  },
  {
    code: "bm",
    name: "Bermuda",
    dialCode: "+1441",
    flag: "🇧🇲",
    phoneNumberLength: 7,
  },
  {
    code: "bt",
    name: "Bhutan",
    dialCode: "+975",
    flag: "🇧🇹",
    phoneNumberLength: 8,
  },
  {
    code: "bo",
    name: "Bolivia",
    dialCode: "+591",
    flag: "🇧🇴",
    phoneNumberLength: 6,
  },
  {
    code: "ba",
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    flag: "🇧🇦",
    phoneNumberLength: 8,
  },
  {
    code: "bw",
    name: "Botswana",
    dialCode: "+267",
    flag: "🇧🇼",
    phoneNumberLength: 7,
  },
  {
    code: "br",
    name: "Brazil",
    dialCode: "+55",
    flag: "🇧🇷",
    phoneNumberLength: 11,
  },
  {
    code: "bn",
    name: "Brunei",
    dialCode: "+673",
    flag: "🇧🇳",
    phoneNumberLength: 7,
  },
  {
    code: "bg",
    name: "Bulgaria",
    dialCode: "+359",
    flag: "🇧🇬",
    phoneNumberLength: 9,
  },
  {
    code: "bf",
    name: "Burkina Faso",
    dialCode: "+226",
    flag: "🇧🇫",
    phoneNumberLength: 8,
  },
  {
    code: "bi",
    name: "Burundi",
    dialCode: "+257",
    flag: "🇧🇮",
    phoneNumberLength: 7,
  },
  {
    code: "kh",
    name: "Cambodia",
    dialCode: "+855",
    flag: "🇰🇭",
    phoneNumberLength: 9,
  },
  {
    code: "cm",
    name: "Cameroon",
    dialCode: "+237",
    flag: "🇨🇲",
    phoneNumberLength: 8,
  },
  {
    code: "ca",
    name: "Canada",
    dialCode: "+1",
    flag: "🇨🇦",
    phoneNumberLength: 10,
  },
  {
    code: "cv",
    name: "Cape Verde",
    dialCode: "+238",
    flag: "🇨🇻",
    phoneNumberLength: 7,
  },
  {
    code: "ky",
    name: "Cayman Islands",
    dialCode: "+1345",
    flag: "🇰🇾",
    phoneNumberLength: 7,
  },
  {
    code: "cf",
    name: "Central African Republic",
    dialCode: "+236",
    flag: "🇨🇫",
    phoneNumberLength: 7,
  },
  {
    code: "td",
    name: "Chad",
    dialCode: "+235",
    flag: "🇹🇩",
    phoneNumberLength: 8,
  },
  {
    code: "cl",
    name: "Chile",
    dialCode: "+56",
    flag: "🇨🇱",
    phoneNumberLength: 9,
  },
  {
    code: "cn",
    name: "China",
    dialCode: "+86",
    flag: "🇨🇳",
    phoneNumberLength: 11,
  },
  {
    code: "co",
    name: "Colombia",
    dialCode: "+57",
    flag: "🇨🇴",
    phoneNumberLength: 10,
  },
  {
    code: "km",
    name: "Comoros",
    dialCode: "+269",
    flag: "🇰🇲",
    phoneNumberLength: 7,
  },
  {
    code: "cg",
    name: "Congo (Brazzaville)",
    dialCode: "+242",
    flag: "🇨🇬",
    phoneNumberLength: 8,
  },
  {
    code: "cd",
    name: "Congo (Kinshasa)",
    dialCode: "+243",
    flag: "🇨🇩",
    phoneNumberLength: 9,
  },
  {
    code: "cr",
    name: "Costa Rica",
    dialCode: "+506",
    flag: "🇨🇷",
    phoneNumberLength: 8,
  },
  {
    code: "ci",
    name: "Côte d’Ivoire",
    dialCode: "+225",
    flag: "🇨🇮",
    phoneNumberLength: 8,
  },
  {
    code: "hr",
    name: "Croatia",
    dialCode: "+385",
    flag: "🇭🇷",
    phoneNumberLength: 9,
  },
  {
    code: "cu",
    name: "Cuba",
    dialCode: "+53",
    flag: "🇨🇺",
    phoneNumberLength: 7,
  },
  {
    code: "cy",
    name: "Cyprus",
    dialCode: "+357",
    flag: "🇨🇾",
    phoneNumberLength: 8,
  },
  {
    code: "cz",
    name: "Czech Republic",
    dialCode: "+420",
    flag: "🇨🇿",
    phoneNumberLength: 9,
  },
  {
    code: "dk",
    name: "Denmark",
    dialCode: "+45",
    flag: "🇩🇰",
    phoneNumberLength: 8,
  },
  {
    code: "dj",
    name: "Djibouti",
    dialCode: "+253",
    flag: "🇩🇯",
    phoneNumberLength: 7,
  },
  {
    code: "dm",
    name: "Dominica",
    dialCode: "+1767",
    flag: "🇩🇲",
    phoneNumberLength: 7,
  },
  {
    code: "do",
    name: "Dominican Republic",
    dialCode: "+1-809",
    flag: "🇩🇴",
    phoneNumberLength: 10,
  },
  {
    code: "ec",
    name: "Ecuador",
    dialCode: "+593",
    flag: "🇪🇨",
    phoneNumberLength: 9,
  },
  {
    code: "eg",
    name: "Egypt",
    dialCode: "+20",
    flag: "🇪🇬",
    phoneNumberLength: 10,
  },
  {
    code: "sv",
    name: "El Salvador",
    dialCode: "+503",
    flag: "🇸🇻",
    phoneNumberLength: 8,
  },
  {
    code: "gq",
    name: "Equatorial Guinea",
    dialCode: "+240",
    flag: "🇬🇶",
    phoneNumberLength: 7,
  },
  {
    code: "er",
    name: "Eritrea",
    dialCode: "+291",
    flag: "🇪🇷",
    phoneNumberLength: 7,
  },
  {
    code: "ee",
    name: "Estonia",
    dialCode: "+372",
    flag: "🇪🇪",
    phoneNumberLength: 7,
  },
  {
    code: "et",
    name: "Ethiopia",
    dialCode: "+251",
    flag: "🇪🇹",
    phoneNumberLength: 9,
  },
  {
    code: "fj",
    name: "Fiji",
    dialCode: "+679",
    flag: "🇫🇯",
    phoneNumberLength: 7,
  },
  {
    code: "fi",
    name: "Finland",
    dialCode: "+358",
    flag: "🇫🇮",
    phoneNumberLength: 9,
  },
  {
    code: "fr",
    name: "France",
    dialCode: "+33",
    flag: "🇫🇷",
    phoneNumberLength: 9,
  },
  {
    code: "ga",
    name: "Gabon",
    dialCode: "+241",
    flag: "🇬🇦",
    phoneNumberLength: 8,
  },
  {
    code: "gm",
    name: "Gambia",
    dialCode: "+220",
    flag: "🇬🇲",
    phoneNumberLength: 7,
  },
  {
    code: "ge",
    name: "Georgia",
    dialCode: "+995",
    flag: "🇬🇪",
    phoneNumberLength: 9,
  },
  {
    code: "de",
    name: "Germany",
    dialCode: "+49",
    flag: "🇩🇪",
    phoneNumberLength: 10,
  },
  {
    code: "gh",
    name: "Ghana",
    dialCode: "+233",
    flag: "🇬🇭",
    phoneNumberLength: 9,
  },
  {
    code: "gr",
    name: "Greece",
    dialCode: "+30",
    flag: "🇬🇷",
    phoneNumberLength: 10,
  },
  {
    code: "gd",
    name: "Grenada",
    dialCode: "+1473",
    flag: "🇬🇩",
    phoneNumberLength: 7,
  },
  {
    code: "gt",
    name: "Guatemala",
    dialCode: "+502",
    flag: "🇬🇹",
    phoneNumberLength: 8,
  },
  {
    code: "gn",
    name: "Guinea",
    dialCode: "+224",
    flag: "🇬🇳",
    phoneNumberLength: 8,
  },
  {
    code: "gw",
    name: "Guinea-Bissau",
    dialCode: "+245",
    flag: "🇬🇼",
    phoneNumberLength: 7,
  },
  {
    code: "gy",
    name: "Guyana",
    dialCode: "+592",
    flag: "🇬🇾",
    phoneNumberLength: 7,
  },
  {
    code: "ht",
    name: "Haiti",
    dialCode: "+509",
    flag: "🇭🇹",
    phoneNumberLength: 8,
  },
  {
    code: "hn",
    name: "Honduras",
    dialCode: "+504",
    flag: "🇭🇳",
    phoneNumberLength: 8,
  },
  {
    code: "hk",
    name: "Hong Kong",
    dialCode: "+852",
    flag: "🇭🇰",
    phoneNumberLength: 8,
  },
  {
    code: "hu",
    name: "Hungary",
    dialCode: "+36",
    flag: "🇭🇺",
    phoneNumberLength: 9,
  },
  {
    code: "is",
    name: "Iceland",
    dialCode: "+354",
    flag: "🇮🇸",
    phoneNumberLength: 7,
  },
  {
    code: "in",
    name: "India",
    dialCode: "+91",
    flag: "🇮🇳",
    phoneNumberLength: 10,
  },
  {
    code: "id",
    name: "Indonesia",
    dialCode: "+62",
    flag: "🇮🇩",
    phoneNumberLength: 10,
  },
  {
    code: "ir",
    name: "Iran",
    dialCode: "+98",
    flag: "🇮🇷",
    phoneNumberLength: 10,
  },
  {
    code: "iq",
    name: "Iraq",
    dialCode: "+964",
    flag: "🇮🇶",
    phoneNumberLength: 10,
  },
  {
    code: "ie",
    name: "Ireland",
    dialCode: "+353",
    flag: "🇮🇪",
    phoneNumberLength: 7,
  },
  {
    code: "il",
    name: "Israel",
    dialCode: "+972",
    flag: "🇮🇱",
    phoneNumberLength: 9,
  },
  {
    code: "it",
    name: "Italy",
    dialCode: "+39",
    flag: "🇮🇹",
    phoneNumberLength: 10,
  },
  {
    code: "jm",
    name: "Jamaica",
    dialCode: "+1876",
    flag: "🇯🇲",
    phoneNumberLength: 7,
  },
  {
    code: "jp",
    name: "Japan",
    dialCode: "+81",
    flag: "🇯🇵",
    phoneNumberLength: 10,
  },
  {
    code: "jo",
    name: "Jordan",
    dialCode: "+962",
    flag: "🇯🇴",
    phoneNumberLength: 8,
  },
  {
    code: "kz",
    name: "Kazakhstan",
    dialCode: "+7",
    flag: "🇰🇿",
    phoneNumberLength: 10,
  },
  {
    code: "ke",
    name: "Kenya",
    dialCode: "+254",
    flag: "🇰🇪",
    phoneNumberLength: 9,
  },
  {
    code: "ki",
    name: "Kiribati",
    dialCode: "+686",
    flag: "🇰🇮",
    phoneNumberLength: 6,
  },
  {
    code: "kp",
    name: "North Korea",
    dialCode: "+850",
    flag: "🇰🇵",
    phoneNumberLength: 9,
  },
  {
    code: "kr",
    name: "South Korea",
    dialCode: "+82",
    flag: "🇰🇷",
    phoneNumberLength: 10,
  },
  {
    code: "kw",
    name: "Kuwait",
    dialCode: "+965",
    flag: "🇰🇼",
    phoneNumberLength: 8,
  },
  {
    code: "kg",
    name: "Kyrgyzstan",
    dialCode: "+996",
    flag: "🇰🇬",
    phoneNumberLength: 9,
  },
  {
    code: "la",
    name: "Laos",
    dialCode: "+856",
    flag: "🇱🇦",
    phoneNumberLength: 9,
  },
  {
    code: "lv",
    name: "Latvia",
    dialCode: "+371",
    flag: "🇱🇻",
    phoneNumberLength: 8,
  },
  {
    code: "lb",
    name: "Lebanon",
    dialCode: "+961",
    flag: "🇱🇧",
    phoneNumberLength: 8,
  },
  {
    code: "ls",
    name: "Lesotho",
    dialCode: "+266",
    flag: "🇱🇸",
    phoneNumberLength: 7,
  },
  {
    code: "lr",
    name: "Liberia",
    dialCode: "+231",
    flag: "🇱🇷",
    phoneNumberLength: 7,
  },
  {
    code: "ly",
    name: "Libya",
    dialCode: "+218",
    flag: "🇱🇾",
    phoneNumberLength: 10,
  },
  {
    code: "li",
    name: "Liechtenstein",
    dialCode: "+423",
    flag: "🇱🇮",
    phoneNumberLength: 7,
  },
  {
    code: "lt",
    name: "Lithuania",
    dialCode: "+370",
    flag: "🇱🇹",
    phoneNumberLength: 8,
  },
  {
    code: "lu",
    name: "Luxembourg",
    dialCode: "+352",
    flag: "🇱🇺",
    phoneNumberLength: 8,
  },
  {
    code: "mk",
    name: "North Macedonia",
    dialCode: "+389",
    flag: "🇲🇰",
    phoneNumberLength: 7,
  },
  {
    code: "mg",
    name: "Madagascar",
    dialCode: "+261",
    flag: "🇲🇬",
    phoneNumberLength: 9,
  },
  {
    code: "mw",
    name: "Malawi",
    dialCode: "+265",
    flag: "🇲🇼",
    phoneNumberLength: 8,
  },
  {
    code: "my",
    name: "Malaysia",
    dialCode: "+60",
    flag: "🇲🇾",
    phoneNumberLength: 9,
  },
  {
    code: "mv",
    name: "Maldives",
    dialCode: "+960",
    flag: "🇲🇻",
    phoneNumberLength: 7,
  },
  {
    code: "ml",
    name: "Mali",
    dialCode: "+223",
    flag: "🇲🇱",
    phoneNumberLength: 8,
  },
  {
    code: "mt",
    name: "Malta",
    dialCode: "+356",
    flag: "🇲🇹",
    phoneNumberLength: 8,
  },
  {
    code: "mh",
    name: "Marshall Islands",
    dialCode: "+692",
    flag: "🇲🇭",
    phoneNumberLength: 7,
  },
  {
    code: "mr",
    name: "Mauritania",
    dialCode: "+222",
    flag: "🇲🇷",
    phoneNumberLength: 8,
  },
  {
    code: "mu",
    name: "Mauritius",
    dialCode: "+230",
    flag: "🇲🇺",
    phoneNumberLength: 7,
  },
  {
    code: "mx",
    name: "Mexico",
    dialCode: "+52",
    flag: "🇲🇽",
    phoneNumberLength: 10,
  },
  {
    code: "fm",
    name: "Micronesia",
    dialCode: "+691",
    flag: "🇫🇲",
    phoneNumberLength: 7,
  },
  {
    code: "md",
    name: "Moldova",
    dialCode: "+373",
    flag: "🇲🇩",
    phoneNumberLength: 8,
  },
  {
    code: "mc",
    name: "Monaco",
    dialCode: "+377",
    flag: "🇲🇨",
    phoneNumberLength: 8,
  },
  {
    code: "mn",
    name: "Mongolia",
    dialCode: "+976",
    flag: "🇲🇳",
    phoneNumberLength: 8,
  },
  {
    code: "me",
    name: "Montenegro",
    dialCode: "+382",
    flag: "🇲🇪",
    phoneNumberLength: 8,
  },
  {
    code: "ma",
    name: "Morocco",
    dialCode: "+212",
    flag: "🇲🇦",
    phoneNumberLength: 9,
  },
  {
    code: "mz",
    name: "Mozambique",
    dialCode: "+258",
    flag: "🇲🇿",
    phoneNumberLength: 9,
  },
  {
    code: "mm",
    name: "Myanmar (Burma)",
    dialCode: "+95",
    flag: "🇲🇲",
    phoneNumberLength: 9,
  },
  {
    code: "na",
    name: "Namibia",
    dialCode: "+264",
    flag: "🇳🇦",
    phoneNumberLength: 7,
  },
  {
    code: "nr",
    name: "Nauru",
    dialCode: "+674",
    flag: "🇳🇷",
    phoneNumberLength: 7,
  },
  {
    code: "np",
    name: "Nepal",
    dialCode: "+977",
    flag: "🇳🇵",
    phoneNumberLength: 10,
  },
  {
    code: "nl",
    name: "Netherlands",
    dialCode: "+31",
    flag: "🇳🇱",
    phoneNumberLength: 9,
  },
  {
    code: "nz",
    name: "New Zealand",
    dialCode: "+64",
    flag: "🇳🇿",
    phoneNumberLength: 9,
  },
  {
    code: "ni",
    name: "Nicaragua",
    dialCode: "+505",
    flag: "🇳🇮",
    phoneNumberLength: 8,
  },
  {
    code: "ne",
    name: "Niger",
    dialCode: "+227",
    flag: "🇳🇪",
    phoneNumberLength: 8,
  },
  {
    code: "ng",
    name: "Nigeria",
    dialCode: "+234",
    flag: "🇳🇬",
    phoneNumberLength: 10,
  },
  {
    code: "no",
    name: "Norway",
    dialCode: "+47",
    flag: "🇳🇴",
    phoneNumberLength: 8,
  },
  {
    code: "om",
    name: "Oman",
    dialCode: "+968",
    flag: "🇴🇲",
    phoneNumberLength: 8,
  },
  {
    code: "pk",
    name: "Pakistan",
    dialCode: "+92",
    flag: "🇵🇰",
    phoneNumberLength: 10,
  },
  {
    code: "pw",
    name: "Palau",
    dialCode: "+680",
    flag: "🇵🇼",
    phoneNumberLength: 6,
  },
  {
    code: "pa",
    name: "Panama",
    dialCode: "+507",
    flag: "🇵🇦",
    phoneNumberLength: 8,
  },
  {
    code: "pg",
    name: "Papua New Guinea",
    dialCode: "+675",
    flag: "🇵🇬",
    phoneNumberLength: 7,
  },
  {
    code: "py",
    name: "Paraguay",
    dialCode: "+595",
    flag: "🇵🇾",
    phoneNumberLength: 9,
  },
  {
    code: "pe",
    name: "Peru",
    dialCode: "+51",
    flag: "🇵🇪",
    phoneNumberLength: 9,
  },
  {
    code: "ph",
    name: "Philippines",
    dialCode: "+63",
    flag: "🇵🇭",
    phoneNumberLength: 10,
  },
  {
    code: "pl",
    name: "Poland",
    dialCode: "+48",
    flag: "🇵🇱",
    phoneNumberLength: 9,
  },
  {
    code: "pt",
    name: "Portugal",
    dialCode: "+351",
    flag: "🇵🇹",
    phoneNumberLength: 9,
  },
  {
    code: "qa",
    name: "Qatar",
    dialCode: "+974",
    flag: "🇶🇦",
    phoneNumberLength: 8,
  },
  {
    code: "ro",
    name: "Romania",
    dialCode: "+40",
    flag: "🇷🇴",
    phoneNumberLength: 10,
  },
  {
    code: "ru",
    name: "Russia",
    dialCode: "+7",
    flag: "🇷🇺",
    phoneNumberLength: 10,
  },
  {
    code: "rw",
    name: "Rwanda",
    dialCode: "+250",
    flag: "🇷🇼",
    phoneNumberLength: 9,
  },
  {
    code: "kn",
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    flag: "🇰🇳",
    phoneNumberLength: 7,
  },
  {
    code: "lc",
    name: "Saint Lucia",
    dialCode: "+1758",
    flag: "🇱🇨",
    phoneNumberLength: 7,
  },
  {
    code: "vc",
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    flag: "🇻🇨",
    phoneNumberLength: 7,
  },
  {
    code: "ws",
    name: "Samoa",
    dialCode: "+685",
    flag: "🇼🇸",
    phoneNumberLength: 7,
  },
  {
    code: "sm",
    name: "San Marino",
    dialCode: "+378",
    flag: "🇸🇲",
    phoneNumberLength: 7,
  },
  {
    code: "st",
    name: "São Tomé and Príncipe",
    dialCode: "+239",
    flag: "🇸🇹",
    phoneNumberLength: 7,
  },
  {
    code: "sa",
    name: "Saudi Arabia",
    dialCode: "+966",
    flag: "🇸🇦",
    phoneNumberLength: 9,
  },
  {
    code: "sn",
    name: "Senegal",
    dialCode: "+221",
    flag: "🇸🇳",
    phoneNumberLength: 8,
  },
  {
    code: "rs",
    name: "Serbia",
    dialCode: "+381",
    flag: "🇷🇸",
    phoneNumberLength: 9,
  },
  {
    code: "sc",
    name: "Seychelles",
    dialCode: "+248",
    flag: "🇸🇨",
    phoneNumberLength: 7,
  },
  {
    code: "sl",
    name: "Sierra Leone",
    dialCode: "+232",
    flag: "🇸🇱",
    phoneNumberLength: 7,
  },
  {
    code: "sg",
    name: "Singapore",
    dialCode: "+65",
    flag: "🇸🇬",
    phoneNumberLength: 8,
  },
  {
    code: "sk",
    name: "Slovakia",
    dialCode: "+421",
    flag: "🇸🇰",
    phoneNumberLength: 9,
  },
  {
    code: "si",
    name: "Slovenia",
    dialCode: "+386",
    flag: "🇸🇮",
    phoneNumberLength: 8,
  },
  {
    code: "sb",
    name: "Solomon Islands",
    dialCode: "+677",
    flag: "🇸🇧",
    phoneNumberLength: 7,
  },
  {
    code: "so",
    name: "Somalia",
    dialCode: "+252",
    flag: "🇸🇴",
    phoneNumberLength: 7,
  },
  {
    code: "za",
    name: "South Africa",
    dialCode: "+27",
    flag: "🇿🇦",
    phoneNumberLength: 9,
  },
  {
    code: "es",
    name: "Spain",
    dialCode: "+34",
    flag: "🇪🇸",
    phoneNumberLength: 9,
  },
  {
    code: "lk",
    name: "Sri Lanka",
    dialCode: "+94",
    flag: "🇱🇰",
    phoneNumberLength: 10,
  },
  {
    code: "sd",
    name: "Sudan",
    dialCode: "+249",
    flag: "🇸🇩",
    phoneNumberLength: 9,
  },
  {
    code: "sr",
    name: "Suriname",
    dialCode: "+597",
    flag: "🇸🇷",
    phoneNumberLength: 7,
  },
  {
    code: "sz",
    name: "Eswatini",
    dialCode: "+268",
    flag: "🇸🇿",
    phoneNumberLength: 7,
  },
  {
    code: "se",
    name: "Sweden",
    dialCode: "+46",
    flag: "🇸🇪",
    phoneNumberLength: 9,
  },
  {
    code: "ch",
    name: "Switzerland",
    dialCode: "+41",
    flag: "🇨🇭",
    phoneNumberLength: 9,
  },
  {
    code: "sy",
    name: "Syria",
    dialCode: "+963",
    flag: "🇸🇾",
    phoneNumberLength: 9,
  },
  {
    code: "tw",
    name: "Taiwan",
    dialCode: "+886",
    flag: "🇹🇼",
    phoneNumberLength: 9,
  },
  {
    code: "tj",
    name: "Tajikistan",
    dialCode: "+992",
    flag: "🇹🇯",
    phoneNumberLength: 9,
  },
  {
    code: "tz",
    name: "Tanzania",
    dialCode: "+255",
    flag: "🇹🇿",
    phoneNumberLength: 9,
  },
  {
    code: "th",
    name: "Thailand",
    dialCode: "+66",
    flag: "🇹🇭",
    phoneNumberLength: 9,
  },
  {
    code: "tl",
    name: "Timor-Leste",
    dialCode: "+670",
    flag: "🇹🇱",
    phoneNumberLength: 7,
  },
  {
    code: "tg",
    name: "Togo",
    dialCode: "+228",
    flag: "🇹🇬",
    phoneNumberLength: 7,
  },
  {
    code: "to",
    name: "Tonga",
    dialCode: "+676",
    flag: "🇹🇴",
    phoneNumberLength: 6,
  },
  {
    code: "tt",
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    flag: "🇹🇹",
    phoneNumberLength: 7,
  },
  {
    code: "tn",
    name: "Tunisia",
    dialCode: "+216",
    flag: "🇹🇳",
    phoneNumberLength: 8,
  },
  {
    code: "tr",
    name: "Turkey",
    dialCode: "+90",
    flag: "🇹🇷",
    phoneNumberLength: 10,
  },
  {
    code: "tm",
    name: "Turkmenistan",
    dialCode: "+993",
    flag: "🇹🇲",
    phoneNumberLength: 9,
  },
  {
    code: "tv",
    name: "Tuvalu",
    dialCode: "+688",
    flag: "🇹🇻",
    phoneNumberLength: 6,
  },
  {
    code: "ug",
    name: "Uganda",
    dialCode: "+256",
    flag: "🇺🇬",
    phoneNumberLength: 9,
  },
  {
    code: "ua",
    name: "Ukraine",
    dialCode: "+380",
    flag: "🇺🇦",
    phoneNumberLength: 10,
  },
  {
    code: "ae",
    name: "United Arab Emirates",
    dialCode: "+971",
    flag: "🇦🇪",
    phoneNumberLength: 9,
  },
  {
    code: "gb",
    name: "United Kingdom",
    dialCode: "+44",
    flag: "🇬🇧",
    phoneNumberLength: 10,
  },
  {
    code: "us",
    name: "United States",
    dialCode: "+1",
    flag: "🇺🇸",
    phoneNumberLength: 10,
  },
  {
    code: "uy",
    name: "Uruguay",
    dialCode: "+598",
    flag: "🇺🇾",
    phoneNumberLength: 9,
  },
  {
    code: "uz",
    name: "Uzbekistan",
    dialCode: "+998",
    flag: "🇺🇿",
    phoneNumberLength: 9,
  },
  {
    code: "vu",
    name: "Vanuatu",
    dialCode: "+678",
    flag: "🇻🇺",
    phoneNumberLength: 7,
  },
  {
    code: "va",
    name: "Vatican City",
    dialCode: "+379",
    flag: "🇻🇦",
    phoneNumberLength: 6,
  },
  {
    code: "ve",
    name: "Venezuela",
    dialCode: "+58",
    flag: "🇻🇪",
    phoneNumberLength: 9,
  },
  {
    code: "vn",
    name: "Vietnam",
    dialCode: "+84",
    flag: "🇻🇳",
    phoneNumberLength: 9,
  },
  {
    code: "ye",
    name: "Yemen",
    dialCode: "+967",
    flag: "🇾🇪",
    phoneNumberLength: 9,
  },
  {
    code: "zm",
    name: "Zambia",
    dialCode: "+260",
    flag: "🇿🇲",
    phoneNumberLength: 9,
  },
  {
    code: "zw",
    name: "Zimbabwe",
    dialCode: "+263",
    flag: "🇿🇼",
    phoneNumberLength: 8,
  },
];

export const getSortedCountryCodes = () => {
  return getCountryCodes.sort((a, b) => a.name.localeCompare(b.name));
};
