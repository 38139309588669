<script lang="ts">

import Vue from 'vue'
import Component from 'vue-class-component'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import {IBookingPage} from "@/store/bookingPageSelector";

@Component({
  name: "BookingPageSelector",
  components: {}
})

export default class BookingPageSelector extends Vue {

  @Action public getBookingPages: Function;
  @Getter public bookingPages: IBookingPage[];


  async created(){
    await this.getBookingPages(this.$route.params.company);
  }

  goto(page){

    //Not in iFrame
    if(window.self== window.top){
      var url = window.location.origin;
      window.location.href = url + "/"+ page.id
    } else {
      //Is in iFrame
      window.top.location.href =  "/"+ page.id;
    }

  }
}

</script>

<template>
<div>
  <v-container style="max-width: 500px">

  <v-row align-content="center" justify="center">
    <v-col align-self="center" cols="12">
      <v-card min-width="350px" >
        <v-card-title>Velg sted</v-card-title>
        <v-divider></v-divider>
        <v-list rounded>
          <v-list-item-group
              color="primary"
          >
            <v-list-item
                v-for="(page, i) in bookingPages"
                :key="i"
                @click="goto(page)"
            >
              <v-list-item-content>
                <v-list-item-title style="color: rgb(26, 147, 208);text-decoration: underline;" v-text="page.name"></v-list-item-title>
                <v-spacer></v-spacer>
              </v-list-item-content>
              <v-list-item-action>                <v-btn dark rounded color="#876AFF">Velg </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>

  </v-container>
</div>
</template>


<style scoped>

</style>
