<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {Getter, Mutation, Action} from "vuex-class";
    import router from '@/router';
    import App from "@/App.vue";
    import firebase from 'firebase'
    import { IUser } from '../../store/user';
    import CustomInput from "@/components/global/styledComponents/CustomInput.vue";



    @Component({
        name:'verification',
        components: {CustomInput, App},
    })
    export default class Verification extends Vue{

        @Action getUserAsAnonymous:(userId:string) => Promise<IUser>;
        @Action public verifyAppointment: Function;

        public verificationCode: string = "";

        public wrongCode: boolean = false;

        @Getter userToBook :IUser;


        async attemptVerification(){
            try{
                await this.verifyAppointment({code: this.verificationCode, userId: this.userToBook.userId});
                this.$emit("verified");
            } catch(e){
                this.wrongCode = true;
            }
        }

    }


</script>
<template>
    <div>
            <v-card class="mt-3" max-width="500">
                <v-card-title>
                    Vennligst verifiser brukeren din
                </v-card-title>

                <v-card-text v-if="userToBook.verificationType === 2">
                    Skriv inn koden som ble sendt til ditt telefonnummer.
                </v-card-text>

                <v-card-text v-if="userToBook.verificationType === 1">
                    Skriv inn koden som ble sendt til din epost.
                </v-card-text>


                <v-card-text>
                    <v-text-field label="Kode" filled v-model="verificationCode">
                    </v-text-field>
                    <!--<custom-input  class="mb-5" :inputType="'text'" v-model="verificationCode"></custom-input>-->
                    <div v-if="wrongCode">
                        <p style="color: red">Kunne ikke verifisere med denne koden</p>
                    </div>
                    <v-btn dark color="#5c6bc0" class="mt-3" @click="attemptVerification()">Verifiser</v-btn>
                </v-card-text>

            </v-card>

    </div>
</template>


<style lang="scss" scoped>
    @import "@/styles/global.scss";


</style>
