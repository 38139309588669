<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { ICustomerAccount } from "../../store/customerAccounts";
import {
  ICustomerAccountDTO,
  ICustomFieldDTO,
  IEditCustomerAccountDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { IUser } from "@/store/user";
import DatePicker from "@/components/global/DatePicker.vue";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { phoneRulesWithLength } from "@/Utilities/rulesUtility";
import { getSortedCountryCodes } from "@/Utilities/phoneNumberUtility";
import { IUserDTO } from "@/types/AutoGenerated/reinforcedTypings";
@Component({
  name: "CustomerAccountForm",
  components: { DatePicker },
})
export default class CustomerAccountForm extends Vue {
  @Prop({}) showPassword;
  @Prop({ default: false }) showPhone;
  @Prop({}) buttonText;

  @Getter customerAccount: ICustomerAccountDTO;
  @Getter userToBook: IUserDTO;
  @Getter customFields: Array<ICustomFieldDTO>;

  customFieldsToPost: any = [];

  @Action postAuditLog: Function;

  public firstName: string = "";
  public emailAddress: string = "";
  public phoneNumber: string = "";
  public address: string = "";
  public postalCode: string = "";
  public city: string = "";
  public SSN: string = "";
  public gender: string = "";
  public birthdate: string = "";
  public newUserPassword: string = "";

  public valid: boolean = false;

  public isDirtyCode = false;

  public nameRules = [];
  public addressRules = [];
  public postalCodeRules = [];
  public cityRules = [];
  public birthdateRules = [];
  public genderRules = [];
  public SSNRules = [];
  public emailRules = [];
  public emailRulesOptional = [];
  public phoneRulesOptional = [];
  public phoneRules = [];
  public passwordRules = [];
  private isShowingDatePicker: boolean = false;
  public phoneNumberCountryCode: number = 0;

  public countryCodes = [];

  public currentCountry: any = {};

  public phoneNumberCountryCodeRules = [];

  async mounted() {
    this.countryCodes = getSortedCountryCodes();
    console.log(this.countryCodes);

    this.nameRules = [
      (v) => !!v || this.$t("bookingForm.nameRequired").toString(),
    ];
    this.addressRules = [
      (v) => !!v || this.$t("bookingForm.addressRequired").toString(),
    ];
    this.postalCodeRules = [
      (v) => !!v || this.$t("bookingForm.postalCodeRequired").toString(),
    ];
    this.cityRules = [
      (v) => !!v || this.$t("customerAccount.cityRequired").toString(),
    ];
    this.birthdateRules = [
      (v) => !!v || this.$t("customerAccount.birthdateRequired").toString(),
    ];
    this.genderRules = [
      (v) => !!v || this.$t("customerAccount.genderRequired").toString(),
    ];
    this.SSNRules = [
      (v) => !!v || this.$t("customerAccount.ssnRequired").toString(),
    ];
    this.emailRules = [
      (v) => !!v || this.$t("bookingForm.emailRequired").toString(),
      (v) =>
        /.+@.+\..+/.test(v) || this.$t("bookingForm.emailValid").toString(),
    ];
    this.emailRulesOptional = [
      (v) =>
        (v && /.+@.+\..+/.test(v)) ||
        this.$t("bookingForm.emailValid").toString(),
    ];
    this.phoneRulesOptional = [
      (v) => /^\d+$/.test(v) || this.$t("bookingForm.phoneValid").toString(),
      (v) => v.length == 8 || this.$t("bookingForm.phoneValid").toString(),
    ];
    this.phoneRules = [
      (v) => /^\d+$/.test(v) || this.$t("bookingForm.phoneValid").toString(),
      (v) =>
        v.length === this.currentCountry.phoneNumberLength ||
        this.$t("bookingForm.phoneLength", {
          length: this.currentCountry.phoneNumberLength,
        }).toString(),
    ];

    this.passwordRules = [
      (v) => !!v || this.$t("bookingForm.passwordRequired").toString(),
      (v) => v.length > 5 || this.$t("bookingForm.passwordLength").toString(),
    ];

    this.phoneNumberCountryCodeRules = [
      (v) => !!v || this.$t("bookingForm.phoneValid").toString(),
      (v) => {
        const validCountryCodes = getSortedCountryCodes().map(
          (country) => country.dialCode
        );
        return (
          validCountryCodes.includes("+" + v) ||
          this.$t("bookingForm.invalidCountryCode").toString()
        );
      },
    ];

    if (this.customerAccount != null) {
      this.address = this.customerAccount.address;
      this.postalCode = this.customerAccount.postalCode;
      this.emailAddress = this.customerAccount.emailAddress;
      this.phoneNumber = this.customerAccount.phoneNumber;
      this.firstName = this.customerAccount.firstName;
      this.city = this.customerAccount.city;
      this.gender = this.customerAccount.gender;
      this.SSN = this.customerAccount.ssn;
      this.gender = this.customerAccount.gender;
      this.birthdate = this.customerAccount.dateOfBirth;
    }

    let firstName = localStorage.getItem("firstName");
    let emailAddress = localStorage.getItem("emailAddress");
    let phoneNumber = localStorage.getItem("phoneNumber");
    let address = localStorage.getItem("address");
    let postalCode = localStorage.getItem("postalCode");
    let city = localStorage.getItem("city");
    if (firstName != null) {
      let audit = {
        action: 3,
        type: 4,
        comment: "",
        message:
          firstName +
          " has booked before! - Autofilling number: " +
          phoneNumber,
        hideForUser: true,
      };

      let userId = this.userToBook.userId;

      let payload = { audit: audit, userId: userId };
      this.postAuditLog(payload);

      this.firstName = firstName;
      this.emailAddress = emailAddress;
      this.phoneNumber = phoneNumber;
      this.address = address;
      this.postalCode = postalCode;
      this.city = city;
    }

    if (this.customFields != null) {
      this.customFieldsToPost = this.customFields.map((x) => {
        return Object.assign(x, { value: "" });
      });
    }

    if (this.userToBook.defaultPhoneNumberCountryCode) {
      this.phoneNumberCountryCode =
        this.userToBook.defaultPhoneNumberCountryCode;
      console.log(
        "Setting current country on default: " +
          this.userToBook.defaultPhoneNumberCountryCode
      );
      this.currentCountry = this.countryCodes.find(
        (country) =>
          country.dialCode ===
          "+" + this.userToBook.defaultPhoneNumberCountryCode
      );

      console.log("Current country: " + this.currentCountry);
    } else {
      //@ts-ignore
      this.phoneNumberCountryCode = 47;
      //this.updateFlag(null);
      this.currentCountry = this.countryCodes.find(
        (country) => country.dialCode === "+47"
      );
    }
  }

  @Watch("phoneNumberCountryCode")
  onPhoneNumberCountryCodeChanged(newCode: number) {
    this.phoneRules = [
      (v) => /^\d+$/.test(v) || this.$t("bookingForm.phoneValid").toString(),
      (v) =>
        v.length === this.currentCountry.phoneNumberLength ||
        this.$t("bookingForm.phoneLength", {
          length: this.currentCountry.phoneNumberLength,
        }).toString(),
    ];
  }

  formatDateString(date: Date) {
    if (date == null) {
      return "";
    }

    //@ts-ignore
    let toDateObject = new Date(date as string);
    if (isNaN(toDateObject.getFullYear())) {
      return "";
    }
    return formatDate(toDateObject, FormatSpaceType.DOT);
  }

  submit() {
    //@ts-ignore
    if (!this.$refs.form.validate()) {
      return;
    }

    let customerAccountUpdate: IEditCustomerAccountDTO = {
      address: this.address,
      emailAddress: this.emailAddress,
      firstName: this.firstName,
      phoneNumber: this.phoneNumber,
      phoneNumberCountryCode: this.phoneNumberCountryCode,
      postalCode: this.postalCode,
      password: this.newUserPassword,
      city: this.city,
      gender: this.gender,
      dateOfBirth: this.birthdate,
      ssn: this.SSN,
      userId: this.userToBook.userId,
      country: "Unknown",
      //@ts-ignore //TODO: Custom fields is probably broken, it doesnt exist on the DTO
      customFields: this.customFieldsToPost,
    };

    this.$emit("submit", customerAccountUpdate);
  }

  public saveDate(event: object, date: any): void {
    this.birthdate = date;
    this.isShowingDatePicker = false;
  }

  public updateFlag(event) {
    // this.isDirtyCode = true;
    // const input = this.phoneNumberCountryCode.trim();
    // Normalize input: ensure it starts with '+'
    //   const normalizedInput = input.startsWith("+") ? input : `+${input}`;

    // Find the country by dial code
    this.currentCountry =
      this.countryCodes.find(
        (country) => country.dialCode === "+" + this.phoneNumberCountryCode
      ) || null;
  }
}
</script>

<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-text-field
          clearable
          v-if="userToBook.customerRequiredName"
          v-model="firstName"
          :rules="nameRules"
          :label="$t('customerAccount.name')"
          required
          name="name"
          outlined
        ></v-text-field>
      </v-row>

      <v-row>
        <v-text-field
          v-model="phoneNumberCountryCode"
          outlined
          type="number"
          @input="updateFlag"
          required
          :rules="phoneNumberCountryCodeRules"
          style="max-width: 100px; margin-right: 10px"
        >
          <template v-slot:prepend-inner>
            <span
              v-if="currentCountry"
              :class="`mr-2 fi fi-${currentCountry.code}`"
            ></span>
            <span style="margin-top: 2px">+</span>
          </template>
        </v-text-field>

        <v-text-field
          clearable
          v-model="phoneNumber"
          :rules="phoneRules"
          :label="$t('customerAccount.phoneNumber')"
          required
          outlined
          type="tel"
          v-if="showPhone && !userToBook.customerRequiredPhoneNumberOptional"
        ></v-text-field>

        <v-text-field
          clearable
          v-model="phoneNumber"
          :label="$t('customerAccount.phoneNumberOptional')"
          outlined
          type="tel"
          v-if="showPhone && userToBook.customerRequiredPhoneNumberOptional"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          clearable
          v-if="
            userToBook.customerRequiredEmail &&
            !userToBook.customerRequiredEmailOptional
          "
          v-model="emailAddress"
          :rules="emailRules"
          :label="$t('customerAccount.email')"
          required
          max-width="300px"
          outlined
          type="email"
        ></v-text-field>

        <v-text-field
          clearable
          v-if="
            userToBook.customerRequiredEmail &&
            userToBook.customerRequiredEmailOptional
          "
          v-model="emailAddress"
          :label="$t('customerAccount.emailOptional')"
          max-width="300px"
          outlined
          type="email"
        ></v-text-field>
      </v-row>

      <v-row>
        <v-text-field
          clearable
          v-if="userToBook.customerRequiredAddress"
          v-model="address"
          :rules="addressRules"
          :label="$t('customerAccount.address')"
          required
          outlined
        ></v-text-field>

        <v-text-field
          v-if="userToBook.customerRequiredCity"
          v-model="city"
          :rules="cityRules"
          :label="$t('customerAccount.city')"
          required
          outlined
        ></v-text-field>
        <v-text-field
          v-if="userToBook.customerRequiredPostalCode"
          v-model="postalCode"
          :rules="postalCodeRules"
          :label="$t('customerAccount.postalCode')"
          required
          outlined
          autocomplete="off"
        ></v-text-field>
      </v-row>

      <v-row v-if="userToBook.customerRequiredSSN">
        <v-text-field
          v-if="userToBook.customerRequiredSSN"
          v-model="SSN"
          :rules="SSNRules"
          :label="$t('customerAccount.ssn')"
          required
          outlined
        ></v-text-field>
      </v-row>

      <v-row v-if="userToBook.customerRequiredBirthdate">
        <p>{{ $t("customerAccount.birthdate") }}</p>
        <v-text-field
          @click="isShowingDatePicker = true"
          type="text"
          :placeholder="$t('customerAccount.birthdate')"
          class="treatment-input"
          :value="formatDateString(birthdate)"
        ></v-text-field>
        <date-picker
          @cancel="isShowingDatePicker = false"
          @save="saveDate"
          :isShowingDatePicker="isShowingDatePicker"
        >
        </date-picker>
      </v-row>

      <v-row v-if="userToBook.customerRequiredGender">
        <p>{{ $t("customerAccount.gender") }}</p>
        <v-radio-group v-model="gender" :mandatory="true" :multiple="false">
          <v-radio value="Mann" :label="$t('customerAccount.male')"></v-radio>
          <v-radio
            value="Kvinne"
            :label="$t('customerAccount.female')"
          ></v-radio>
        </v-radio-group>
      </v-row>

      <v-row v-if="showPassword">
        <v-text-field
          v-model="newUserPassword"
          :rules="passwordRules"
          :label="$t('customerAccount.password')"
          required
          outlined
          type="password"
          autocomplete="off"
        ></v-text-field>
      </v-row>

      <v-row
        v-if="customFieldsToPost != null"
        v-for="(customField, index) in customFieldsToPost"
        :key="index"
      >
        <v-text-field
          clearable
          v-model="customField.value"
          :label="customField.label"
          :required="!customField.optional"
          max-width="300px"
          outlined
        ></v-text-field>
      </v-row>
    </v-form>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        style="margin-left: -10px"
        v-if="buttonText == 'Lagre'"
        color="success"
        @click="submit"
        ><v-icon>save</v-icon>{{ buttonText }}
        <v-icon>mdi-arrow-right</v-icon></v-btn
      >
      <v-btn
        style="margin-left: -10px"
        v-else
        dark
        color="#5C6BC0"
        @click="submit"
        >{{ buttonText }} <v-icon class="mx-1">mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<style scoped></style>
