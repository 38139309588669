import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { APIURL2 } from "@/main";
import { IAppointmentTypeDTO } from "@/types/AutoGenerated/reinforcedTypings";
export const apiURL = "https://bookingsystembackend2.azurewebsites.net/api/";

// Interface
export interface appointmentTypeState {
  appointmentType: IappointmentType;
  categories: Array<IServiceCategory>;
}

export interface IServiceBookingDetail {
  service: IAppointmentTypeDTO;
  children: IAppointmentTypeDTO[];
}

export interface IAvailableHoursRequest {
  userId: string;
  year: number;
  month: number;
  day: number;
  serviceBookings: IAppointmentTypeDTO[];
}
// Interface Model
export interface IappointmentType {
  id: number;
  adminId: string;
  title: string;
  price: number;
  description: string;
  durationInMinutes: number;
}

export interface IServiceCategory {
  id: number;
  title: string;
  order: number;
}

// Mutation enums
export const enum mutationStringAppointmentType {
  setAppointmentType = "setAppointmentType",
  SET_APPOINTMENT_TYPE = "setAppointmentType",
  SET_CATEGORIES = "setCategories",
}
export const enum actionStringAppointmentType {
  GET_APPOINTMENT_TYPES_AS_CUSTOMER = "getAppointmentTypesAsCustomer",
}
export const enum getterStringAppointmentType {
  APPOINTMENT_TYPE = "appointmentType",
}

export const state = {
  appointmentType: {},
  categories: {},
};

export const getters: GetterTree<any, appointmentTypeState> = {
  appointmentType: (state) => state.appointmentType,
  categories: (state) => state.categories,
};

export const mutations: MutationTree<appointmentTypeState> = {
  setAppointmentType(state, payload: IappointmentType) {
    state.appointmentType = payload;
  },
  setCategories(state, payload: Array<IServiceCategory>) {
    state.categories = payload;
  },
};

export const actions: ActionTree<appointmentTypeState, any> = {
  getAppointmentTypesAsCustomer({ commit }, userId) {
    axios
      .get(APIURL2 + "AppointmentType/asAnonymous?userId=" + userId)
      .then((res) => {
        commit(mutationStringAppointmentType.SET_APPOINTMENT_TYPE, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export const appointmentTypes = {
  actions,
  mutations,
  getters,
  state,
};
