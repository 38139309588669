<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import { IUser } from "@/store/user";
import {
  IAppointmentTypeDTO,
  IUserDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
} from "@/types/formatDateHelper";
import { DialogMutations, IDialog } from "@/store/dialog";
import { formatCurrency } from "@/Utilities/currencyHelper";
@Component({
  name: "ServiceItem",
  components: {},
})
export default class ServiceItem extends Vue {
  @Getter public userToBook: IUserDTO;

  @Prop({}) category;
  @Prop({}) selectedServices;

  public showExtraDialog: boolean = false;
  public selectedService: any = null;

  onSelect(service) {
    if (service.firstAvailable == null) {
      let dialog: IDialog = {
        text: this.$t("serviceItem.noAvailableTimes").toString(),
      };

      this.$store.commit(DialogMutations.AddToDialogQueue, dialog);
      return;
    }
    if (this.userToBook.multipleServices && this.isChosen(service)) {
      this.$emit("uncheck", service);
      return;
    }

    if (service.children != null && service.children.length > 0) {
      this.selectedService = service;
      this.showExtraDialog = true;
    } else {
      this.confirm(service);
    }
  }

  confirm(service) {
    if (this.userToBook.multipleServices == false) {
      this.$emit("onselect", service);
    } else {
      if (this.isChosen(service)) {
        this.$emit("uncheck", service);
      } else {
        this.$emit("check", service);
      }
    }
  }
  created() {}

  isChosen(service) {
    let s = this.selectedServices.find((x) => {
      return x.id === service.id;
    });
    return s != null;
  }

  getServiceSubTitle(service): string {
    let returnValue = this.getDurationString(service);
    if (this.userToBook.showServicePrice) {
      returnValue +=
        " ● " +
        this.$t("currency", {
          amount: formatCurrency(service.price),
        }).toString();
    }
    return returnValue;
  }

  formatDateString(date: Date, friendly = false) {
    if (date == null) {
      return "";
    }

    const today = new Date();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const tomorrow2 = new Date();
    tomorrow2.setDate(tomorrow.getDate() + 1);

    let toDateObject = new Date(date as any);

    if (friendly && toDateObject.getDate() == today.getDate()) {
      return this.$t("serviceItem.today").toString();
    }

    if (friendly && toDateObject.getDate() == tomorrow.getDate()) {
      return this.$t("serviceItem.tomorrow").toString();
    }

    if (friendly && toDateObject.getDate() == tomorrow2.getDate()) {
      return this.$t("serviceItem.dayAfterTomorrow").toString();
    }

    return formatDate(toDateObject, FormatSpaceType.DOT, false);
  }

  changeChildSelected(event, child) {
    child.selected = event;
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  submit() {
    this.confirm(this.selectedService);
    this.showExtraDialog = false;
  }

  getChildLabel(child) {
    let label =
      child.title +
      " +" +
      this.$t("currency", { amount: formatCurrency(child.price) }).toString();
    if (child.durationInMinutes > 0) {
      label += "● " + this.getDurationString(child);
    }
    return label;
  }

  getDurationString(service) {
    if (service.durationInMinutes > 60) {
      let minutes = service.durationInMinutes % 60;
      const hours = Math.floor(service.durationInMinutes / 60);

      if (minutes > 0) {
        if (hours == 1) {
          return (
            hours +
            " " +
            this.$t("serviceItem.hour").toString() +
            " " +
            minutes +
            " " +
            this.$t("serviceItem.min").toString()
          );
        } else {
          return (
            hours +
            " " +
            this.$t("serviceItem.hours").toString() +
            " " +
            minutes +
            " " +
            this.$t("serviceItem.min").toString()
          );
        }
      } else {
        return hours + " " + this.$t("serviceItem.hours").toString();
      }
    } else {
      return (
        service.durationInMinutes + " " + this.$t("serviceItem.min").toString()
      );
    }
  }
}
</script>

<template>
  <div>
    <v-dialog max-width="500" v-model="showExtraDialog">
      <v-card v-if="selectedService != null">
        <v-card-title>{{
          $t("serviceItem.selectAdditionalServices")
        }}</v-card-title>

        <v-card-text class="pa-5">
          <div v-for="child in selectedService.children">
            <v-checkbox
              :value="child.selected"
              @change="changeChildSelected($event, child)"
              :label="getChildLabel(child)"
              color="primary"
              hide-details
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!userToBook.multipleServices"
            style="margin-left: -10px"
            color="success"
            @click="submit"
            >{{ $t("serviceItem.continue") }}
            <v-icon>mdi-arrow-right</v-icon></v-btn
          >
          <v-btn
            v-if="userToBook.multipleServices"
            style="margin-left: -10px"
            color="success"
            @click="submit"
            >{{ $t("serviceItem.confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-list-item
      v-for="service in category.services"
      :key="service.id"
      @click="onSelect(service)"
      style="border: solid 1px lightgray"
    >
      <v-list-item-content style="flex: 4 !important">
        <v-row>
          <v-col cols="auto">
            <div v-if="userToBook.multipleServices">
              <v-checkbox
                style="margin: 0 !important"
                :value="isChosen(service)"
              >
              </v-checkbox>
            </div>
          </v-col>
          <v-col>
            <v-list-item-title
              color="#1A93D0"
              style="color: #1a93d0; text-decoration: underline"
              v-text="service.title"
            ></v-list-item-title>
            <v-list-item-subtitle
              style="white-space: inherit; max-width: 300px"
              class="hidden-xs-only"
              >{{ service.description }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              style="white-space: inherit; max-width: 300px; font-weight: bold"
              class="hidden-xs-only"
              v-if="service.firstAvailable != null"
              >{{ $t("serviceItem.firstAvailable") }}
              {{
                formatDateString(service.firstAvailable, true)
              }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              style="white-space: inherit; max-width: 300px"
              class="hidden-xs-only"
              v-if="service.firstAvailable == null"
              >{{ $t("serviceItem.noAvailableTimes") }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              v-if="service.discountPrice === -1"
              style="white-space: inherit"
              class="hidden-sm-and-up"
              >{{ service.description }} <v-spacer></v-spacer>
              {{ getServiceSubTitle(service) }}
            </v-list-item-subtitle>

            <v-list-item-subtitle
              style="font-weight: bold"
              class="hidden-sm-and-up"
              v-if="service.firstAvailable != null"
            >
              {{ $t("serviceItem.firstAvailable") }}
              {{
                formatDateString(service.firstAvailable, true)
              }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              style="white-space: inherit; max-width: 300px"
              class="hidden-sm-and-up"
              v-if="service.firstAvailable == null"
              >{{ $t("serviceItem.noAvailableTimes") }}</v-list-item-subtitle
            >
            <div v-if="service.discountPrice > -1">
              <v-row class="py-0" style="margin-bottom: -12px">
                <v-col class="pt-0">
                  <v-list-item-subtitle
                    style="white-space: inherit"
                    class="hidden-sm-and-up"
                    >{{ service.description }} <v-spacer></v-spacer>
                    {{ getDurationString(service) }}</v-list-item-subtitle
                  >
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col class="pt-0" cols="auto">
                  <v-list-item-subtitle
                    v-if="userToBook.showServicePrice"
                    style="white-space: inherit; text-decoration: line-through"
                    class="hidden-sm-and-up"
                  >
                    <v-spacer></v-spacer>
                    {{
                      $t("currency", { amount: formatCurrency(service.price) })
                    }}
                  </v-list-item-subtitle>
                </v-col>

                <v-col
                  v-if="userToBook.showServicePrice"
                  class="pt-0"
                  cols="auto"
                >
                  <v-list-item-subtitle
                    style="
                      white-space: inherit;
                      color: red;
                      display: inline-block;
                    "
                    class="hidden-sm-and-up"
                  >
                    <v-spacer></v-spacer
                    >{{
                      $t("currency", {
                        amount: formatCurrency(service.discountPrice),
                      })
                    }}</v-list-item-subtitle
                  >
                </v-col>
              </v-row>
            </div>
          </v-col>

          <!--        <v-col>-->
          <!--         -->
          <!--        </v-col>-->
        </v-row>
      </v-list-item-content>

      <v-list-item-content class="hidden-xs-only" style="text-align: right">
        <span>{{ getDurationString(service) }}</span>
      </v-list-item-content>
      <v-list-item-content
        v-if="service.discountPrice === -1 && userToBook.showServicePrice"
        class="hidden-xs-only"
        style="text-align: right"
      >
        <span>{{ $t("currency", { amount: service.price }) }}</span>
      </v-list-item-content>
      <v-list-item-content
        v-if="userToBook.showServicePrice && service.discountPrice != -1"
        class="hidden-xs-only"
        style="text-align: right"
      >
        <span style="text-decoration: line-through; display: inline-block">{{
          $t("currency", { amount: formatCurrency(service.price) })
        }}</span>
        <span style="color: red; display: inline-block">{{
          $t("currency", { amount: formatCurrency(service.discountPrice) })
        }}</span>
      </v-list-item-content>

      <!--<v-list-item-action>-->
      <!--<v-btn icon>-->
      <!--<v-icon  v-if="service.description != null && service.description.length > 0" @click.stop="showInfo(service.description)"  class="hidden-sm-and-up"  color="grey lighten-1">mdi-information</v-icon>-->
      <!--</v-btn>-->
      <!--</v-list-item-action>-->
    </v-list-item>
  </div>
</template>

<style scoped></style>
