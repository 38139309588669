import { GetterTree, MutationTree, ActionTree } from "vuex";
import axios from "axios";
import { APIURL2 } from "../../main";
import { actionStringSnackbar } from "../snackbar";
import {
  appointmentsState,
  IBookedAppointmentCustomer,
  IBookedAppointments,
  selectedDate,
  selectHour,
  IAppointmentForm,
  IAppointments,
  AppointmentInfo,
  AppointmentChangeAsCustomAccount,
} from "@/store/appointments/appointmentTypings";
import { CustomerAccountsMutations } from "../customerAccounts";
import { DialogActions, DialogMutations, IDialog } from "@/store/dialog";
import { IAvailableHour } from "@/store/workingHours";
import { ICustomerAccountDTO } from "@/types/AutoGenerated/reinforcedTypings";

// Mutation enums
export const enum mutationStringAppointments {
  setSelectedDate = "setSelectedDate",
  setSelectedHour = "setSelectedHour", //Gradly remove
  SET_SELECTED_HOUR = "setSelectedHour",
  setBookedAppointmentCustomers = "setBookedAppointmentCustomers",
}
export const enum actionStringAppointments {
  BOOK_APPOINTMENT_AS_ANONYMOUS = "bookAppointmentAsAnonymous",
}

export const state: Partial<appointmentsState> = {
  appointments: null,
  selectedDate: null,
  selectHour: null,
  bookedAppointments: null,
  bookedAppointmentCustomers: null,
  latestDeletedAppointment: null,
  allAppointments: null,
  appointmentsForToday: null,
  loggedInCustomer: null,
};

export const getters: GetterTree<any, appointmentsState> = {
  appointments: (state) => state.appointments,
  selectedDate: (state) => state.selectedDate,
  selectHour: (state) => state.selectHour,
  bookedAppointments: (state) => state.bookedAppointments,
  bookedAppointmentCustomers: (state) => state.bookedAppointmentCustomers,
  latestDeletedAppointment: (state) => state.latestDeletedAppointment,
  allAppointments: (state) => state.allAppointments,
  appointmentsForToday: (state) => state.appointmentsForToday,
  loggedInCustomer: (state) => state.loggedInCustomer,
};

export const mutations: MutationTree<appointmentsState> = {
  setAppointments(state, payload: IAppointments) {
    state.appointments = payload;
  },
  setSelectedDate(state, payload: selectedDate) {
    state.selectedDate = payload;
  },
  setSelectedHour(state, payload: selectHour) {
    state.selectHour = payload;
  },
  setBookedAppointments(state, payload: IBookedAppointments) {
    state.bookedAppointments = payload;
  },
  setBookedAppointmentCustomers(state, payload: IBookedAppointmentCustomer) {
    state.bookedAppointmentCustomers = payload;
  },
  setLatestDeletedAppointment(state, payload: any) {
    state.latestDeletedAppointment = payload;
  },
  setAllAppointments(state, payload: AppointmentInfo[]) {
    state.allAppointments = payload;
  },
  setAppointmentsForToday(state, payload) {
    state.appointmentsForToday = payload;
  },
  setLoggedInCustomer(state, payload) {
    state.loggedInCustomer = payload;
  },
};

export const actions: ActionTree<appointmentsState, any> = {
  selectDateToBook({ commit }, selectedDate: selectedDate): void {
    commit(mutationStringAppointments.setSelectedDate, selectedDate);
  },
  selectHourToBook({ commit }, selectedHour: IAvailableHour): void {
    commit(mutationStringAppointments.setBookedAppointmentCustomers, null);
    commit(mutationStringAppointments.setSelectedHour, selectedHour);
  },

  async fetchCustomerByToken({ dispatch, commit }, payload): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(
            APIURL2 +
              "Customer/FetchByToken/" +
              payload.customerToken +
              "/" +
              payload.userId +
              "/" +
              payload.phoneNumber
          )
          .then(
            (response) => {
              commit("setLoggedInCustomer", response.data);
              localStorage.setItem("customerToken", payload.customerToken);
              localStorage.setItem(
                "customerTokenPhoneNumber",
                payload.phoneNumber
              );

              let customer = response.data.customer;

              //This is for autofilling booking fields
              localStorage.setItem("firstName", customer.firstName);
              localStorage.setItem("emailAddress", customer.emailAddress);
              localStorage.setItem("phoneNumber", customer.phoneNumber);
              localStorage.setItem("address", customer.address);
              localStorage.setItem("postalCode", customer.postalCode);
              localStorage.setItem("city", customer.city);
              localStorage.setItem(
                "phoneNumberCountryCode",
                customer.phoneNumberCountryCode
              );

              return resolve(response);
            },
            (error) => {
              commit(DialogMutations.AddToDialogQueue, {
                text: "Feil kode/telefonnummer.",
              });
            }
          );
      } catch (e) {
        reject(e);
      }
    });
  },

  async changeAppointmentAsCustomerAccount(
    { dispatch },
    appointment: AppointmentChangeAsCustomAccount
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(APIURL2 + "Appointment/ChangeAsCustomerAccount", appointment)
          .then(
            (response) => {
              return resolve(response);
            },
            (error) => {
              dispatch(
                actionStringSnackbar.postSnackbarMessage,
                "Kunne ikke endre notat."
              );
            }
          );
      } catch (e) {
        reject(e);
      }
    });
  },

  async sendVerificationCode(
    { dispatch },
    appointment: IAppointmentForm
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          APIURL2 +
            "Appointment/verificationCode/?userId=" +
            appointment.userId,
          appointment
        )
        .then(
          (response) => {
            return resolve(response);
          },
          (error) => {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              "Kunne ikke sende verifikasjonskode."
            );
          }
        );
    });
  },
  async bookAppointmentAsAnonymous(
    { dispatch, commit, getters },
    appointment: IAppointmentForm
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            APIURL2 + "Appointment?userId=" + appointment.userId,
            appointment
          )
          .then(
            (response) => {
              return resolve(response);
            },
            (error) => {
              let dialog: IDialog = {
                text:
                  "En uforventet feil skjedde. Vennligst prøv igjen, eller ring for å bestille på :" +
                  getters.userToBook.phoneNumber,
              };
              dispatch(DialogActions.AddToDialogQueue, dialog);
              commit("setBookingStep", 1);
            }
          );
      } catch (e) {
        if (e.response.data === "Cannot book appointment - it's occupied") {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            "Timen du prøver å bestille overlapper med neste time. Velg en kortere time."
          );
          reject(e);
        }
      }
    });
  },

  async getCustomerAccountAppointments(
    { dispatch },
    customerAccount: ICustomerAccountDTO
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(APIURL2 + "Appointment/forCustomerAccount", customerAccount, {})
          .then((response) => {
            return resolve(response.data);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async verifyAppointment(
    { dispatch, commit },
    verificationCode
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            APIURL2 +
              "Appointment/verify?verificationCode=" +
              verificationCode.code +
              "&userId=" +
              verificationCode.userId
          )
          .then(
            (response) => {
              commit(
                CustomerAccountsMutations.SetCustomerAccount,
                response.data
              );
              return resolve(response);
            },
            (error) => {
              dispatch(
                actionStringSnackbar.postSnackbarMessage,
                "Feil verifiseringskode"
              );
              reject(error);
            }
          );
      } catch (e) {
        dispatch(
          actionStringSnackbar.postSnackbarMessage,
          "Feil verifiseringskode"
        );
        reject(e);
      }
    });
  },
};

export const appointments = {
  actions,
  mutations,
  getters,
  state,
};
