<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {ButtonSize} from "@/types/ButtonSize";



    @Component({
        name: 'custom-button',
    })


    export default class customButton extends Vue {
        @Prop({default:ButtonSize.LARGE}) size:ButtonSize;
        @Prop({default:'Knapp'}) buttonText:string;
        @Prop({default:true}) filled:boolean;
        @Prop({default:false}) isAlert:boolean;
        @Prop({default:false}) isGrey:boolean;
        @Prop({default:false}) isFlat:boolean;
        @Prop({default:false}) disabled:boolean;


        private classList(): string {
            let classList: Array<string> = [];

            classList.push("custom-button--" + this.size);
            // classList.push("custom-button__color--" + this.color);

            if(this.filled){
                classList.push("custom-button--" + 'filled');
            } else {
                classList.push("custom-button--" + 'not-filled');
            }
            if(this.isAlert){
                classList.push("custom-button--" + 'alert');
            }
            if(this.isGrey){
                classList.push("custom-button--" + 'grey');
            }
            if(this.isFlat){
                classList.push("custom-button--" + 'flat');
            }
            if(this.disabled){
                classList.push("custom-button--" + 'disabled');
            }

            return classList.join(" ");
        }

        public clickHandler(event):void{
            this.$emit('custom-button-click', event);
        }

    }
</script>

<template>
    <button @click="clickHandler" :class="classList()"  class="custom-button">
        {{buttonText}}
    </button>
</template>


<style lang="scss" scoped>


    .custom-button {
        width:auto;
        padding:5px 10px;
        border-radius: 4px;
        box-shadow:1px 1px 4px 1px #d0d0d0;


        &--small {

        }

        &--medium {

        }

        &--large {
            padding: 8px 20px;
        }
        &--filled {
            background: #56dcb6;
            font-weight: bold;
            border-radius: 26px;
            background:green;
            color:#333;

            &:hover{
                background:greenLightish;

            }
        }
        &--not-filled{
            background:white;
            border-radius: 2px solid green;
            color:green;
        }
        &--alert{
            background:red;
            &:hover{
                background:#9e1500;
            }
        }
        &--grey{
            background: #c9c9c9;
            &:hover{
                background:darkgrey;
            }
        }
        &--flat{
            background:white;
            &:hover{
                background: #cccccc;
            }
        }
        &--disabled{
            cursor: default;
            opacity: 0.5;
        }
    }

</style>
